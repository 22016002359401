<template>
  <div class="performancAction">
    <div class="group">
      <div class="group-label">薪资项目</div>
      <a-dropdown
        style="width: 100px; margin-right: 10px;"
        :trigger="['click']"
      >
        <a class="ant-dropdown-link">
          添加项目
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay" @click="changeItems">
          <a-menu-item v-for="(item, index) in itemsList" :key="index">
            {{ item.Name }}
          </a-menu-item>
        </a-menu>
      </a-dropdown>
      <a-tree-select
        :disabled="isRead"
        :defaultValue="Level"
        show-search
        style="width: 200px; margin-right: 10px;"
        :treeData="orgList"
        placeholder="请选择部门"
        allow-clear
        @change="onChangeSchool"
      >
      </a-tree-select>
    </div>

    <div class="content" v-for="(item, index) in itemArr" :key="index">
      <div class="content-head">
        <div class="head-text">项目名称</div>
        <div class="head-text" style="width: 180px;">前提字段</div>
        <div class="head-text">条件</div>
        <div class="head-text">数值</div>
        <div class="head-text">组合方式</div>
        <div style="width: 80px;"></div>
        <div class="head-item" style="width: 180px;">计算字段</div>
        <div class="head-item">运算符</div>
        <div class="head-item">数值</div>
      </div>
      <div
        class="content-warp"
        v-for="(formula, formulaIndex) in item.formula"
        :key="formulaIndex"
        v-dragging="{
          item: formula,
          list: item.formula,
          group: 'formula_' + index,
          index: index,
          changeKey: 'formula',
        }"
      >
        <div class="content-title">
          <div style="width: 120px;" class="title-text">
            {{ formula.ItemName }}
          </div>
          <!--                    <a-button class="title&#45;&#45;icon"  size="small">删除</a-button>-->
        </div>
        <!--                条件项-->
        <div class="content-list">
          <div
            class="list"
            v-for="(condition, conditionIndex) in formula.condition"
            :key="conditionIndex"
          >
            <!--                        <a-select size="small" style="width: 180px" class="content-item" show-search-->
            <!--                                  v-model="condition.DataField">-->
            <!--                            <a-select-option v-for="fieldItem in premiseField" :value="fieldItem">-->
            <!--                                {{ fieldItem }}-->
            <!--                            </a-select-option>-->
            <!--                        </a-select>-->
            <a-mentions
              :prefix="['$']"
              v-model="condition.DataField"
              size="small"
              style="width: 180px;"
              class="content-item"
            >
              <a-mentions-option
                v-for="value in premiseField[prefix] || []"
                :key="value"
                :value="value"
              >
                {{ value }}
              </a-mentions-option>
            </a-mentions>

            <a-select
              size="small"
              class="content-item"
              v-model="condition.Operator"
            >
              <a-select-option
                v-for="(RelationalArrItem, RelationalArrIndex) in RelationalArr"
                :key="RelationalArrIndex"
                :value="RelationalArrItem.value"
              >
                {{ RelationalArrItem.name }}
              </a-select-option>
            </a-select>
            <a-input
              size="small"
              class="content-item"
              v-model="condition.Value"
              placeholder="请输入数值"
            />
            <a-select
              size="small"
              class="content-item"
              v-model="condition.AndOr"
            >
              <a-select-option
                v-for="(PostconditionItem,
                PostconditionIndex) in PostconditionArr"
                :key="PostconditionIndex"
                :value="PostconditionItem.value"
              >
                {{ PostconditionItem.name }}
              </a-select-option>
            </a-select>
            <div class="action-icon">
              <a-icon
                @click="_conditionDel(index, formulaIndex, conditionIndex)"
                class="del-icon"
                type="close-circle"
              />
              <a-icon
                v-show="formula.condition.length - 1 == conditionIndex"
                @click="_conditionAdd(index, formulaIndex, conditionIndex)"
                class="color-blue"
                type="plus-circle"
              />
            </div>
          </div>
        </div>
        <!--                计算项-->
        <div>
          <div
            class="content-right"
            style="margin-bottom: 5px;"
            v-for="(count, countIndex) in formula.count"
            :key="countIndex"
          >
            <a-select
              size="small"
              class="right-item"
              style="width: 180px;"
              v-model="count.ResultCountItem"
              show-search
            >
              <a-select-option
                v-for="(fieldItem, fieldIndex) in countField"
                :key="fieldIndex"
                :value="fieldItem"
              >
                {{ fieldItem }}
              </a-select-option>
            </a-select>
            <a-select
              size="small"
              class="right-item"
              v-model="count.ResultCountType"
            >
              <a-select-option
                v-for="(operatorItem, operatorIndex) in operatorArr"
                :key="operatorIndex"
                :value="operatorItem.value"
              >
                {{ operatorItem.name }}
              </a-select-option>
            </a-select>
            <a-input
              size="small"
              class="right-item"
              v-model="count.Result"
              placeholder="请输入数值"
            />
            <div class="action-icon">
              <a-icon
                v-show="formula.count.length > 1"
                @click="_countDel(index, formulaIndex, countIndex)"
                class="del-icon"
                type="close-circle"
              />
              <a-icon
                v-show="formula.count.length - 1 == countIndex"
                @click="_countAdd(index, formulaIndex, countIndex)"
                class="color-blue"
                type="plus-circle"
              />
            </div>
          </div>
        </div>
      </div>
      <!--            后置条件-->
      <div class="postcondition-title">
        <div class="postcondition-title-text">后置条件</div>
        <a-button
          class="postcondition-icon"
          size="small"
          @click="_postcondition(index)"
          >添加</a-button
        >
      </div>
      <div
        class="content-warp"
        v-for="(postcondition, postconditionIndex) in item.postcondition"
        :key="postconditionIndex"
        v-dragging="{
          item: postcondition,
          list: item.postcondition,
          group: 'postcondition_' + index,
          index: index,
          changeKey: 'postcondition',
        }"
      >
        <div class="content-title">
          <div class="title-text">{{ postcondition.ItemName }}</div>
        </div>
        <div class="content-list">
          <div
            class="list"
            v-for="(PositionCondition,
            PositionConditionIndex) in postcondition.PositionCondition"
            :key="PositionConditionIndex"
          >
            <a-mentions
              size="small"
              style="width: 180px;"
              class="content-item"
              :prefix="['$']"
              v-model="PositionCondition.DataField"
            >
              <a-mentions-option
                v-for="value in premiseField[prefix] || []"
                :key="value"
                :value="value"
              >
                {{ value }}
              </a-mentions-option>
            </a-mentions>

            <a-select
              size="small"
              class="content-item"
              v-model="PositionCondition.Operator"
            >
              <a-select-option
                v-for="(RelationalArrItem, RelationalArrIndex) in RelationalArr"
                :key="RelationalArrIndex"
                :value="RelationalArrItem.value"
              >
                {{ RelationalArrItem.name }}
              </a-select-option>
            </a-select>

            <a-input
              size="small"
              class="content-item"
              v-model="PositionCondition.Value"
              placeholder="请输入数值"
            />
            <a-select
              size="small"
              class="content-item"
              v-model="PositionCondition.AndOr"
            >
              <a-select-option
                v-for="(PostconditionItem,
                PostconditionIndex) in PostconditionArr"
                :key="PostconditionIndex"
                :value="PostconditionItem.value"
              >
                {{ PostconditionItem.name }}
              </a-select-option>
            </a-select>
            <div class="action-icon">
              <a-icon
                @click="
                  _postconditionDel(
                    index,
                    postconditionIndex,
                    PositionConditionIndex
                  )
                "
                class="del-icon"
                type="close-circle"
              />
              <a-icon
                v-show="
                  postcondition.PositionCondition.length - 1 ==
                  PositionConditionIndex
                "
                @click="
                  _postconditionAdd(
                    index,
                    postconditionIndex,
                    PositionConditionIndex
                  )
                "
                class="color-blue"
                type="plus-circle"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            class="content-right"
            style="margin-bottom: 5px;"
            v-for="(PositionCount,
            PositionCountIndex) in postcondition.PositionCount"
            :key="PositionCountIndex"
          >
            <a-select
              size="small"
              style="width: 180px;"
              class="right-item"
              v-model="PositionCount.ResultCountItem"
              show-search
            >
              <a-select-option
                v-for="(fieldItem, fieldIndex) in countField"
                :key="fieldIndex"
                :value="fieldItem"
              >
                {{ fieldItem }}
              </a-select-option>
            </a-select>
            <a-select
              size="small"
              class="right-item"
              v-model="PositionCount.ResultCountType"
            >
              <a-select-option
                v-for="(operatorItem, operatorIndex) in operatorArr"
                :key="operatorIndex"
                :value="operatorItem.value"
              >
                {{ operatorItem.name }}
              </a-select-option>
            </a-select>
            <a-input
              size="small"
              class="right-item"
              v-model="PositionCount.Result"
              placeholder="请输入数值"
            />
            <div class="action-icon">
              <a-icon
                v-show="postcondition.PositionCount.length > 1"
                @click="
                  _PositionCountDel(
                    index,
                    postconditionIndex,
                    PositionCountIndex
                  )
                "
                class="del-icon"
                type="close-circle"
              />
              <a-icon
                v-show="
                  postcondition.PositionCount.length - 1 == PositionCountIndex
                "
                @click="
                  _PositionCountAdd(
                    index,
                    postconditionIndex,
                    PositionCountIndex
                  )
                "
                class="color-blue"
                type="plus-circle"
              />
            </div>
          </div>
        </div>
      </div>
      <!--            属性-->
      <div class="postcondition-title">
        <div class="postcondition-title-text">属性</div>
        <a-button
          class="postcondition-icon"
          size="small"
          @click="_attrAdd(index)"
          >添加</a-button
        >
        <div style="flex: 1; display: flex; flex-wrap: wrap;">
          <div
            v-if="item.hasOwnProperty('attr')"
            style="display: flex; align-items: center; margin-left: 10px;"
            v-for="(attrItem, attrIndex) in item.attr"
            :key="attrIndex"
          >
            <div
              class="attr"
              v-for="(attrListItem, attrListIndex) in attrList"
              :key="attrListIndex"
              v-if="attrListItem.Name == attrIndex"
            >
              <div class="attr-label" v-if="attrListItem.Name == attrIndex">
                {{ attrIndex }}
              </div>
              <a-input
                v-model="item.attr[attrIndex]"
                size="small"
                class="attr-item"
                v-if="attrListItem.Type == 'input'"
                placeholder="请输入数值"
              />
              <!--                            -->
              <a-select
                @change="changeAttr($event, attrIndex, index)"
                v-if="attrListItem.Type == 'select'"
                v-model="item.attr[attrIndex]"
                class="attr-item"
                size="small"
              >
                <a-select-option
                  v-for="(AttrValueItem,
                  AttrValueIndex) in attrListItem.AttrValue"
                  :key="AttrValueIndex"
                  :value="AttrValueItem"
                >
                  {{ AttrValueItem }}
                </a-select-option>
              </a-select>
              <a-icon
                style="margin-left: 10px;"
                @click="_attrDel(index, attrIndex)"
                class="del-icon"
                type="close-circle"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e8e8e8',
        padding: '10px 16px',
        textAlign: 'left',
        left: 0,
        background: '#fff',
        borderRadius: '0 0 4px 4px',
      }"
    >
      <a-button style="marginright: 8px;" @click="onClose">
        关闭
      </a-button>
      <a-button type="primary" @click="_action()">
        确定
      </a-button>
    </div>
    <!--        属性添加start-->
    <a-drawer
      v-if="attrVisible"
      title="属性"
      width="600"
      :closable="false"
      :visible="attrVisible"
      @close="() => attrModal(false)"
    >
      <div class="data-action">
        <div class="data-group" v-for="(item, index) in attrList" :key="index">
          <div class="data-label">{{ item.Name }}</div>
          <!--                    :checked="Person"-->
          <a-checkbox @change="changeCheck($event, item.Name)"></a-checkbox>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => attrModal(false)">
          取消
        </a-button>
        <a-button type="primary" @click="attrAction">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!--        属性添加end-->
  </div>
</template>

<script>
import VueDND from 'awe-dnd'
import Vue from 'vue'
import { TreeSelect } from 'ant-design-vue'

Vue.use(VueDND)

export default {
  name: 'performancAction',
  data() {
    return {
      itemsList: [],
      itemsIndex: -1,
      premiseField: { $: ['个人净业绩', '个人毛业绩', '个人课耗', '个人退费'] },
      countField: [],
      itemArr: [],
      operatorArr: [
        { name: '加法', value: '+' },
        { name: '减法', value: '-' },
        {
          name: '乘法',
          value: '*',
        },
        { name: '除法', value: '%' },
        { name: '等于', value: '=' },
      ],
      PostconditionArr: [
        { name: '无', value: 0 },
        { name: '并且', value: 1 },
        {
          name: '或者',
          value: 2,
        },
        { name: '并列计算', value: 3 },
      ],
      AndOrObject: { '0': '无', '1': '并且', '2': '或者', '3': '并列计算' },
      RelationalArr: [
        { name: '大于', value: '>' },
        { name: '大于等于', value: '>=' },
        {
          name: '小于',
          value: '<',
        },
        { name: '小于等于', value: '<=' },
        { name: '等于', value: '=' },
        {
          name: '不等于',
          value: '!=',
        },
        { name: '包含', value: 'in' },
      ],
      mouseKey: -1,
      data: {},
      OrgId: this.Level,
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      attrIndex: -1,
      attrVisible: false,
      attrList: [],
      attrData: [],
      timer: new Date().getTime(),
      prefix: '$',
      orgList: [],
    }
  },
  props: {
    PositionName: {
      type: String,
    },
    positionId: {
      type: String,
    },
    Month: {
      type: String,
    },
    treeDataSchool: {
      type: Array,
    },
    OrgIds: {
      type: String,
    },
    Level: {
      type: String,
    },
    isRead: {
      type: Boolean,
    },
  },
  mounted() {
    // 拖拽后触发的事件
    this.$dragging.$on('dragged', (res) => {
      let list = res.value.list
      let data = []
      for (let i = 0; i < list.length; i++) {
        if (list[i].hasOwnProperty('Id')) {
          let obj = {}
          obj.Id = list[i].Id
          obj.Sort = i
          data.push(obj)
        }
      }
      let type = res.value.changeKey
      if (data.length > 0) {
        this.$axios.get(
          129450,
          {
            data: JSON.stringify(data),
            type: type,
          },
          (r) => {
            return r
          }
        )
      }
    })
  },
  created() {
    this.items()
    this.field_list()
    this._countField()
    if (this.Level && typeof this.Level != 'undefined') {
      this.Level = this.Level
      this.loadData()
    }
    this._getLevel()
    this.attr_list()
  },
  methods: {
    //属性start
    _attrDel(index, attrIndex) {
      this.$delete(this.itemArr[index].attr, attrIndex)
      // this.itemArr[index].attr.splice(attrIndex, 1);
      // this.$forceUpdate(); //强制更新数组
      // console.log(this.itemArr[index])
    },
    changeAttr(val, field, index) {
      // console.log(val, field,index)
      this.itemArr[index].attr[field] = val
      this.$forceUpdate()
    },
    attrModal(val) {
      this.attrVisible = val
      if (!val) {
        this.attrData = []
        this.attrIndex = -1
      }
    },
    attr_list() {
      let self = this
      this.$axios.get(129458, {}, (res) => {
        if (res.data.code == 1) {
          self.attrList = res.data.data
        }
      })
    },
    changeCheck(e, name) {
      let data = {
        name: name,
        bool: e.target.checked,
      }
      this.attrData.push(data)
    },
    attrAction() {
      let self = this
      if (
        !self.itemArr[self.attrIndex].hasOwnProperty('attr') ||
        !self.itemArr[self.attrIndex].attr
      ) {
        self.itemArr[self.attrIndex].attr = {}
      }
      for (let i = 0; i < self.attrData.length; i++) {
        if (self.attrData[i].bool) {
          self.itemArr[self.attrIndex].attr[self.attrData[i].name] = ''
        }
      }
      // console.log( this.itemArr[self.attrIndex].attr)
      // console.log(self.attrData)
      // if(this.itemArr[this.attrIndex].hasOwnProperty('attr')){
      //     for(let i=0;i<self.attrData.length;i++){
      //         if(self.attrData[i].bool){
      //             let obj= {};
      //             obj[self.attrData[i].name]  =""
      //             this.itemArr[self.attrIndex].attr.push(obj)
      //         }
      //
      //     }
      // }else{
      //     self.itemArr[self.attrIndex].attr = [];
      //     for(let i=0;i<self.attrData.length;i++){
      //         if(self.attrData[i].bool){
      //             let obj= {};
      //             obj[self.attrData[i].name]  =""
      //             this.itemArr[self.attrIndex].attr.push(obj)
      //         }
      //
      //     }
      // }
      this.attrModal(false)
    },
    //属性end
    loadData() {
      let self = this
      this.$message.loading('加载中..', 0)
      this.$axios.get(
        129449,
        {
          Id: self.positionId,
          Month: self.Month,
          OrgId: self.OrgId,
          Level: self.Level,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.itemArr = res.data.data
            let id = []
            for (let i = 0; i < self.itemArr.length; i++) {
              if (
                self.itemArr[i].formula.length == 0 &&
                self.itemArr[i].postcondition.length == 0
              ) {
                if (self.itemArr[i].hasOwnProperty('Id')) {
                  id.push(self.itemArr[i].Id)
                  self.itemArr.splice(i, 1)
                }
              }
              if (
                !self.itemArr[i].hasOwnProperty('attr') ||
                !self.itemArr[i].attr
              ) {
                self.itemArr[i].attr = {}
              }
              //
            }
            if (id.length > 0) {
              this.item_del(id)
            }
          }
        }
      )
    },
    //添加部门
    onChangeSchool(value) {
      let self = this
      if (value && typeof value != 'undefined') {
        this.$axios.get(
          1294557,
          {
            Position: self.PositionName,
            Month: self.Month,
            OrgId: value,
          },
          (res) => {
            if (res.data.code == 1) {
              //已存在
              self.Level = ''
              this.$message.error(res.data.msg)
            } else {
              self.Level = value
            }
          }
        )
      }
    },
    field_list() {
      let self = this
      this.$axios.get(
        129439,
        {
          Condition: 1,
        },
        (res) => {
          if (res.data.code == 1) {
            let obj = {
              $: res.data.data,
            }
            self.premiseField = obj
          }
        }
      )
    },
    _countField() {
      let self = this
      this.$axios.get(
        129439,
        {
          Count: 1,
        },
        (res) => {
          if (res.data.code == 1) {
            self.countField = res.data.data
          }
        }
      )
    },
    _getLevel() {
      let self = this
      self.$axios.get(8012, {}, (res) => {
        if (res.data.code == 1) {
          self.orgList = res.data.data
        } else {
          self.orgList = []
        }
      })
    },
    onClose() {
      let id = []
      for (let i = 0; i < this.itemArr.length; i++) {
        if (
          this.itemArr[i].formula.length == 0 &&
          this.itemArr[i].postcondition.length == 0
        ) {
          if (this.itemArr[i].hasOwnProperty('Id')) {
            id.push(this.itemArr[i].Id)
          }
        }
      }
      if (id.length > 0) {
        this.item_del(id)
      }
      if (typeof this.OrgId == 'undefined') {
        this.OrgId = ''
      }
      this.$emit('visible', this.OrgId)
    },

    items() {
      let self = this
      this.$axios.get(129437, {}, (res) => {
        if (res.data.code == 1) {
          self.itemsList = res.data.data
        }
      })
    },
    _action() {
      let self = this
      // if (!self.OrgId) {
      //     this.$message.error('请先选择部门');
      //     return false;
      // }

      this.$message.loading('操作中....', 0)
      this.$axios.post(
        129448,
        {
          data: JSON.stringify(this.itemArr),
          Month: self.Month,
          Position: self.PositionName,
          OrgId: self.Level,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.onClose()
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    //计算项
    _countAdd(index, formulaIndex, countIndex) {
      let count = {
        Result: '',
        ResultCountItem: '',
        ResultCountType: '',
        PositionItemId: this.itemArr[index].formula[formulaIndex].count[
          countIndex
        ].PositionItemId,
      }
      this.itemArr[index].formula[formulaIndex].count.push(count)
    },
    _countDel(index, formulaIndex, countIndex) {
      if (this.itemArr[index].formula[formulaIndex].count.length != 1) {
        this.itemArr[index].formula[formulaIndex].count.splice(countIndex, 1)
      }
    },
    //条件添加
    _conditionAdd(index, formulaIndex, conditionIndex) {
      let condition = {
        DataField: '',
        Operator: '',
        Value: '',
        AndOr: '',
        PositionItemId: this.itemArr[index].formula[formulaIndex].condition[
          conditionIndex
        ].PositionItemId,
      }
      this.itemArr[index].formula[formulaIndex].condition.push(condition)
    },
    _conditionDel(index, formulaIndex, conditionIndex) {
      let Id = this.itemArr[index].formula[formulaIndex].Id
      if (this.itemArr[index].formula[formulaIndex].condition.length == 1) {
        this.itemArr[index].formula.splice(formulaIndex, 1)
        if (this.itemArr[index].formula.length == 0) {
          this.itemArr.splice(index, 1)
          let ids = []
          ids.push(Id)
          this.item_del(ids)
        }
      } else {
        this.itemArr[index].formula[formulaIndex].condition.splice(
          conditionIndex,
          1
        )
      }
    },
    //项目名称删除
    item_del(ids) {
      this.$axios.get(
        129447,
        {
          id: ids.join(','),
        },
        (res) => {
          if (res.data.code == 1) {
            // this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },

    //后置条件
    _postcondition(index) {
      let PositionItemId = this.itemArr[index].formula[0].condition[0]
        .PositionItemId
      let ItemName = this.itemArr[index].formula[0].ItemName

      let conditionArr = {
        PositionCondition: [
          {
            DataField: '',
            Operator: '',
            Value: '',
            AndOr: '',
            PositionItemId: PositionItemId,
            GroupId: '',
          },
        ],
        PositionCount: [
          {
            ResultCountItem: '',
            ResultCountType: '',
            Result: '',
            PositionItemId: PositionItemId,
            GroupId: '',
          },
        ],
        ItemName: ItemName,
        PositionItemId: PositionItemId,
      }

      this.itemArr[index].postcondition.push(conditionArr)

      for (let i = 0; i < this.itemArr.length; i++) {
        for (let s = 0; s < this.itemArr[i].postcondition.length; s++) {
          for (
            let x = 0;
            x < this.itemArr[i].postcondition[s].PositionCondition.length;
            x++
          ) {
            this.itemArr[i].postcondition[s].PositionCondition[x].GroupId = i
          }
        }
        for (let j = 0; j < this.itemArr[i].postcondition.length; j++) {
          for (
            let y = 0;
            y < this.itemArr[i].postcondition[j].PositionCount.length;
            y++
          ) {
            this.itemArr[i].postcondition[j].PositionCount[y].GroupId = i
          }
        }
      }
    },
    _postconditionDel(index, postconditionIndex, PositionArrIndex) {
      if (
        this.itemArr[index].postcondition[postconditionIndex].PositionCondition
          .length == 1
      ) {
        this.itemArr[index].postcondition.splice(postconditionIndex, 1)
      } else {
        this.itemArr[index].postcondition[
          postconditionIndex
        ].PositionCondition.splice(PositionArrIndex, 1)
      }
      for (let i = 0; i < this.itemArr.length; i++) {
        for (let s = 0; s < this.itemArr[i].postcondition.length; s++) {
          for (
            let x = 0;
            x < this.itemArr[i].postcondition[s].PositionCondition.length;
            x++
          ) {
            this.itemArr[i].postcondition[s].PositionCondition[x].GroupId = i
          }
        }
        for (let j = 0; j < this.itemArr[i].postcondition.length; j++) {
          for (
            let y = 0;
            y < this.itemArr[i].postcondition[j].PositionCount.length;
            y++
          ) {
            this.itemArr[i].postcondition[j].PositionCount[y].GroupId = i
          }
        }
      }
    },
    _postconditionAdd(index, postconditionIndex, PositionArrIndex) {
      let postcondition = {
        DataField: '',
        Operator: '',
        Value: '',
        AndOr: '',
        PositionItemId: this.itemArr[index].postcondition[postconditionIndex]
          .PositionCondition[PositionArrIndex].PositionItemId,
      }

      this.itemArr[index].postcondition[
        postconditionIndex
      ].PositionCondition.push(postcondition)
    },
    _PositionCountAdd(index, postconditionIndex, PositionCountIndex) {
      let PositionCount = {
        ResultCountItem: '',
        ResultCountType: '',
        GroupId: '',
        Result: '',
        PositionItemId: this.itemArr[index].postcondition[postconditionIndex]
          .PositionCount[PositionCountIndex].PositionItemId,
      }

      this.itemArr[index].postcondition[postconditionIndex].PositionCount.push(
        PositionCount
      )
    },
    _PositionCountDel(index, postconditionIndex, PositionCountIndex) {
      this.itemArr[index].postcondition[
        postconditionIndex
      ].PositionCount.splice(PositionCountIndex, 1)
    },
    changeItems(val) {
      if(!this.Level){
        this.$message.error(`请先选择部门`)
        return false
      }
      this.itemsIndex = val.key
      let ItemName = this.itemsList[this.itemsIndex].Name

      let self = this
      this.$axios.get(
        129446,
        {
          Month: self.Month,
          Position: self.PositionName,
          ItemName: ItemName,
          OrgId: self.Level
        },
        (res) => {
          if (res.data.code == 1) {
            let id = res.data.id
            let formula = {
              ItemName: ItemName,
              PositionItemId: id,
              condition: [
                {
                  DataField: '',
                  Operator: '',
                  Value: '',
                  AndOr: '',
                  PositionItemId: id,
                },
              ],
              count: [
                {
                  ResultCountItem: '',
                  ResultCountType: '',
                  Result: '',
                  PositionItemId: id,
                },
              ],
            }

            let ItemNameArr = {
              formula: [],
              postcondition: [],
              attr: {},
            }
            ItemNameArr.formula.push(formula)
            let activity = 0
            let num = ''
            for (let i = 0; i < this.itemArr.length; i++) {
              for (let s = 0; s < this.itemArr[i].formula.length; s++) {
                if (this.itemArr[i].formula[s].ItemName == ItemName) {
                  activity = 1
                  num = i
                }
              }
            }
            if (activity == 1) {
              this.itemArr[num].formula.push(formula)
            } else {
              this.itemArr.push(ItemNameArr)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _attrAdd(index) {
      this.attrIndex = index
      this.$nextTick(() => {
        // 在 DOM 中添加 my-component 组件
        this.attrModal(true)
      })
    },
  },
}
</script>

<style scoped lang="less">
.attr {
  display: flex;
  align-items: center;
  //margin-bottom: 10px;
  .attr-label {
    white-space: nowrap;
    word-break: break-all;
  }

  .attr-item {
    min-width: 100px;
    margin-left: 10px;
  }
}

.group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;

  .group-label {
    width: 80px;
    white-space: nowrap;
    word-break: break-all;
  }

  .group-item {
    flex: 1;
  }
}

.content {
  border: 1px solid #e5e7ea;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 15px;

  .content-head {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .head-text {
      width: 120px;
      text-align: center;
      margin-right: 20px;
      border: 1px solid #e5e7ea;
      border-radius: 4px;
    }

    .head-item {
      width: 120px;
      text-align: center;
      margin-right: 5px;
      border: 1px solid #e5e7ea;
      border-radius: 4px;
    }
  }

  .content-warp {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .content-title {
      width: 120px;
      text-align: center;
      margin-right: 20px;

      .title--icon {
        color: red;
        //text-align: center;
        //margin: auto;
        margin-top: 5px;
      }
    }

    .content-list {
      .list {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .content-item {
          width: 120px;
          margin-right: 20px;
        }
      }
    }

    .content-right {
      display: flex;

      .right-item {
        width: 120px;
        margin-right: 5px;
      }
    }
  }

  .postcondition-title {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .postcondition-title-text {
      color: #000c17;
      font-size: 14px;
      font-weight: 700;
      margin-right: 10px;
      min-width: 60px;
    }
  }
}

.del-icon {
  color: red;
  font-size: 18px;
  margin-right: 8px;
  cursor: pointer;
}

.color-blue {
  color: rgb(24, 144, 255) !important;
  font-size: 18px;
  cursor: pointer;
}

.action-icon {
  width: 80px;
  display: flex;
}

.data-action {
  margin-bottom: 60px;

  .data-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .data-label {
      font-size: 14px;
      width: 80px;
    }

    .data-item {
      flex: 1;
    }
  }
}
</style>

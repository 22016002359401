<template>
  <div class="positionMatch">
  	<div class="serach">
  		<a-month-picker @change="changeMonth" style="margin-bottom: 20px" :format="monthFormat"/>
  	</div>
  	<a-table :columns="columns" :dataSource="data" :loading="tableLoading">
	    <div slot="UnMatchNum" slot-scope="text, record, index">
	    	<span :style="{cursor: 'pointer',color: record.UnMatchNum > 0 ? '#faad14' : '#DDD'}" @click="showList(record)">{{record.UnMatchNum}}</span>
	    </div>
	</a-table>

	<a-drawer title="请修改职位或者添加绩效职位" width="800" :closable="false" :visible="showListState" @close="closeList">
		<a-table :columns="show_columns" :dataSource="show_data" :loading="show_tableLoading" :pagination='false'>
		    <div slot="action" slot-scope="text, record, index">
		    	<span style="cursor: pointer;color: #40a9ff;" @click="operation(record)">修改</span>
		    </div>
		</a-table>
		<div class="pagination" v-if="Total > 0">
			<a-pagination :current="currentPage" :total="Total" @change="changePage"/>
		</div>
		<div class="operation">
			<a-modal v-model="operationModel" :title="ModelTitle" @ok="save" @onCancel="ModelCancel">
			    <div class="model-item">
			    	<span>绩效职位：</span>
			    	<a-select show-search v-model="positionName" @change="changePosition" placeholder="请选择职位" >
	                    <a-select-option v-for="(item,index) in positionList" :value="item.Position">{{ item.Position }}</a-select-option>
	                </a-select>
			    </div>
			    <div class="model-item">
			    	<span>职位级别：</span>
			    	<a-select v-model='positionLevel' placeholder="请选择级别" >
	                    <a-select-option v-for="(item,index) in LevelList" :value="item.Name">{{ item.Name }}</a-select-option>
	                </a-select>
			    </div>
                <div class="model-item">
                	<span>绩效档次：</span>
                	<a-select v-model="PerformLevel" placeholder="请选择绩效档次" >
	                    <a-select-option v-for="(item,index) in PerformLevelList" :value="item.Name">{{ item.Name }}</a-select-option>
	                </a-select>
                </div>
		    </a-modal>
		</div>
	</a-drawer>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'positionMatch',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      data: [],
      columns: [
        {
          title: '部门',
          dataIndex: 'title',
          key: 'title'
        },
        {
          title: '未匹配人数',
          dataIndex: 'UnMatchNum',
          width: '50',
          scopedSlots: { customRender: 'UnMatchNum' },
          key: 'UnMatchNum'
        },
      ],
      show_columns: [
        {
          title: '序号',
          dataIndex: 'Number',
          key: 'Number'
        },
        {
          title: '姓名',
          dataIndex: 'Name',
          key: 'Name'
        },
        {
          title: '部门',
          dataIndex: 'ParentName',
          key: 'ParentName'
        },
        {
          title: '职位',
          dataIndex: 'Position',
          key: 'Position',
          ellipsis: true,
        },
        {
          title: '绩效职位',
          dataIndex: 'PerformPosition',
          key: 'PerformPosition'
        },
        {
          title: '绩效档次',
          dataIndex: 'PerformLevel',
          key: 'PerformLevel'
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      monthFormat: 'YYYY-MM',
      Month: '',
      tableLoading: false,
      showListState: false,
      show_tableLoading: false,
      show_data: [],
      currentPage: 1,
      Total: 0,
      orgid: '',
      operationModel: false,
      positionList: [],
      PerformLevelList: [],
      LevelList: [],
      positionLevel: '',
      positionName: '',
      PerformLevel: '',
      ModelTitle: '',
      operationUserId: '',
    }
  },
  created () {

  },
  mounted(){
  	let month = moment().format(this.monthFormat);
  	this.Month = month;
  	this._getData(month);
  },
  methods: {
  	moment,
  	changeMonth(val){
  		let month = moment(val).format(this.monthFormat);
  		this.Month = month;
  		this._getData(month);
  	},
  	_getData(month){
  		this.tableLoading = true;
  		this.$axios.get(8000207, {month: month}, res => {
            if (res.data.code == 1) {
                this.data = res.data.data;
            } else {
                this.$message.error(res.data.message)
            }
            this.tableLoading = false;
        });
  	},
  	showList(record){
  		this.orgid = record.value;
  		this.showListState = true;
  		this._getMatchUser();
  	},
  	_getMatchUser(){
  		this.show_tableLoading = true;
  		this.$axios.get(8000208, {month: this.Month,orgid: this.orgid,pagesize: this.currentPage}, res => {
            if (res.data.code == 1) {
                this.show_data = res.data.data;
                this.Total = res.data.count;
            } else {
            	this.show_data = [];
            	this.Total = 0;
                this.$message.error(res.data.message)
            }
            this.show_tableLoading = false;
        });
  	},
  	closeList(){
  		this.currentPage = 1;
  		this.showListState = false;
  	},
  	changePage(current, pageSize){
  		this.currentPage = current;
  		this._getMatchUser();
  	},
  	position_list() {
        let self = this;
        this.$axios.get(129435, {}, res => {
            if (res.data.code == 1) {
                self.positionList = res.data.data;
            } else {
                self.positionList = [];
            }
        })
    },
    _getPerformLevel(){
        let self =this;
        self.$axios.get(8000206, {}, res => {
            console.log(res.data.data);
            if (res.data.code == 1) {
                self.PerformLevelList = res.data.data;
            } else {
                self.PerformLevelList = [];
            }
        })
    },
    changePosition(value){
    	this.positionName = value;
    	this.$axios.get(8000209, {position: value}, res => {
    		this.positionLevel = '';
            if (res.data.code == 1) {
                this.LevelList = res.data.data;
            } else {
                this.LevelList = [];
            }
        })
    },
    changePerformLevel(value){

    },
  	operation(record){
  		this.initModelParam();
  		this.operationUserId = record.Id;
  		this.ModelTitle = record.Name;
  		this.operationModel = true;
  		this.position_list();
    	this._getPerformLevel();
  	},
  	save(){
  		if (!this.positionName) {
  			this.$message.error('请选择绩效职位');
  			return;
  		}
  		// if (!this.positionLevel) {
  		// 	this.$message.error('请选择绩效级别');
  		// 	return;
  		// }
  		if (!this.PerformLevel) {
  			this.$message.error('请选择绩效档次');
  			return;
  		}

  		let data = {
  			Id: this.operationUserId,
  			positionName: this.positionName,
  			positionLevel: this.positionLevel,
  			PerformLevel: this.PerformLevel
  		};

  		this.$axios.post(8000210, data, res => {
            if (res.data.code == 1) {
            	this._getMatchUser();
            	this.$message.success(res.data.message);
            } else {
                this.$message.error(res.data.message);
            }
            this.initModelParam();
        })
  	},
  	ModelCancel(){
  		this.initModelParam();
  	},
  	initModelParam(){
  		this.operationModel = false;
  		this.operationUserId = '';
  		this.positionName = '';
  		this.positionLevel = '';
  		this.PerformLevel = '';
  	},
  }
}
</script>

<style lang="less" scoped>
	.pagination {
		margin-top: 25px;
    	text-align: end;
	}
	.model-item{
		margin-bottom:5px;
		.ant-select{
			width: 200px;
    		margin-left: 10px;
		}
	}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContent"},[_c('a-modal',{attrs:{"title":_vm.List.hasOwnProperty('Title')?_vm.List.Title:'',"ok-text":"确认","cancel-text":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{key:"submit",attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v(" 知道了 ")])],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.List.Content)}})],2),_c('a-drawer',{attrs:{"title":_vm.List.hasOwnProperty('Title')?_vm.List.Title:'',"width":"600","closable":false,"visible":_vm.visibleDrawer},on:{"close":_vm.onClose}},[_c('div',{staticStyle:{"margin-bottom":"60px"},domProps:{"innerHTML":_vm._s(_vm.List.Content)}}),_c('div',{style:({
       position: 'absolute',
       bottom: 0,
       width: '100%',
       borderTop: '1px solid #e8e8e8',
       padding: '10px 16px',
       textAlign: 'left',
       left: 0,
       background: '#fff',
       borderRadius: '0 0 4px 4px',
     })},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onClose}},[_vm._v(" 知道了 ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
   <div class="pageContent">
       <a-modal
           v-model="visible"
           :title="List.hasOwnProperty('Title')?List.Title:''"
           ok-text="确认" :cancel-text="false" 
       >
           <template slot="footer">

               <a-button key="submit" type="primary" @click="handleOk">
                   知道了
               </a-button>
           </template>
          <div v-html="List.Content">
          </div>
       </a-modal>
       <a-drawer
           :title="List.hasOwnProperty('Title')?List.Title:''"
           width="600"
           :closable="false"
           :visible="visibleDrawer"
           @close="onClose"
       >
           <div style="margin-bottom: 60px" v-html="List.Content"></div>
           <div
               :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
           >
               <a-button type="primary" @click="onClose">
                   知道了
               </a-button>
           </div>
       </a-drawer>
   </div>
</template>

<script>
export default {
    name: "pageContent",
    data(){
        return{
            visible:false,
            Columns:[],
            List:{},
            visibleDrawer:false,
        }
    },
    props:{
        Id:{
            type:String,
        },
        ModelType:{
            type:String,
            default:'drawer',//drawer侧边栏  modal对话框
        }
    },
    created() {
        this.loadData();
    },
    methods:{
        loadData(){
            let self = this;
            this.$message.loading('加载中...',0);
            this.$axios.get(129466,{
                Id:self.Id
            },res=>{
                this.$message.destroy();
                if(res.data.code==1){
                    self.Columns = res.data.column;
                    let data =res.data.data[0];
                    let Columns = [];
                    for(let i=0;i<res.data.column.length;i++){
                        Columns.push(res.data.column[i].FieldName)
                    }
                    self.Columns =  Columns;
                    self.List = data;
                    if(self.ModelType=='modal'){
                        self.visible =true;
                    }else{
                        self.visibleDrawer = true;
                    }

                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },

        handleOk(){
            this.visible = false;
            this.$emit('_coloseTips','','');
        },
        onClose(){
            this.visibleDrawer = false;
            this.$emit('_coloseTips','','');
        },

    }
}
</script>

<style scoped lang="less">

</style>
<template>
  <div class="performanc">
    <a-tabs
      :style="
        'width:' +
        ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
        'px;'
      "
      default-active-key="1"
      @change="callback"
    >
      <a-tab-pane key="1" tab="绩效管理">
        <a-month-picker
          v-model="Month"
          @change="changeMonth"
          style="margin-bottom: 20px;"
          :format="monthFormat"
        />
        <a-select
          show-search
          style="width: 200px; margin-bottom: 20px; margin-left: 10px;"
          @change="position_detail"
          v-model="positionName"
          placeholder="请选择职位"
        >
          <a-select-option
            v-for="(item, index) in positionList"
            :key="index"
            :value="item.Position"
            >{{ item.Position }}</a-select-option
          >
        </a-select>
        <a-select
          v-show="orgList.length > 0"
          style="width: 200px; margin-left: 10px;"
          v-model="new_level"
          placeholder="请选择薪酬档"
          allow-clear
          @change="changeOrg"
        >
          <a-select-option
            v-for="(item, index) in orgList"
            :key="index"
            :value="item.Id"
            >{{ item.OrgName }}</a-select-option
          >
        </a-select>
        <a-button
          type="default"
          v-if="!addStatus"
          style="margin-left: 10px; width: 200px;"
          @click="_add"
          >添加&nbsp;&nbsp;&nbsp;+</a-button
        >

        <a-button type="primary" style="float: right;" @click="_copyItem"
          >复制</a-button
        >
        <div class="content">
          <div class="content-left"></div>
          <div class="content-right">
            <div
              class="content-list"
              v-for="(item, index) in tableList"
              :key="index"
            >
              <div class="content-list-title">
                {{ item.ItemName }}
                <a-icon
                  type="copy"
                  style="margin-left: 10px; cursor: pointer;"
                  @click="_copyOne(item.Id)"
                />
              </div>
              <div class="content-right-head">
                <div class="right-head-item list-field-more">项目名称</div>
                <div class="right-head-item list-field-more">前提字段</div>
                <div class="right-head-item">条件</div>
                <div class="right-head-item">数值</div>
                <div class="right-head-item">组合方式</div>
                <!--                                style="background: rgb(240, 248, 255);"-->
                <div class="right-head-item list-field-more">计算字段</div>
                <div class="right-head-item">运算符</div>
                <div class="right-head-item right-head-num">数值</div>
              </div>
              <div
                class="content-right-warp"
                v-for="(formula, index) in item.formula"
                :key="index"
              >
                <div class="right-warp-title list-field-more">
                  <a-tooltip
                    placement="topLeft"
                    :title="formula.Id"
                    arrow-point-at-center
                  >
                    {{ formula.ItemName }}
                  </a-tooltip>
                </div>
                <div class="right-warp-list">
                  <div
                    class="warp-list"
                    v-for="(condition, index) in formula.condition"
                    :key="index"
                  >
                    <div class="warp-list-item list-field-more">
                      {{ condition.DataField }}
                    </div>
                    <div class="warp-list-item">{{ condition.Operator }}</div>
                    <div class="warp-list-item">{{ condition.Value }}</div>
                    <div class="warp-list-item">
                      {{ AndOrObject[condition.AndOr] }}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    class="right-warp-right"
                    v-for="(count, index) in formula.count"
                    :key="index"
                  >
                    <div class="right-warp-right-item list-field-more">
                      {{ count.ResultCountItem }}
                    </div>
                    <div class="right-warp-right-item">
                      {{ count.ResultCountType }}
                    </div>
                    <div class="right-warp-right-item right-head-num">
                      <a-tooltip
                        placement="topLeft"
                        :title="count.Result"
                        arrow-point-at-center
                      >
                        {{ count.Result }}
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="margin-top: 5px; color: red;"
                v-if="item.postcondition.length > 0"
              >
                后置条件
              </div>
              <div
                class="content-right-warp"
                v-for="(postcondition,
                postconditionIndex) in item.postcondition"
                :key="postconditionIndex"
                :style="
                  postconditionIndex == 0 ? 'border-top:1px solid #e5e7ea' : ''
                "
              >
                <div class="right-warp-title list-field-more">
                  <a-tooltip
                    placement="topLeft"
                    :title="postcondition.Id"
                    arrow-point-at-center
                  >
                    {{ postcondition.ItemName }}
                  </a-tooltip>
                </div>
                <div class="right-warp-list">
                  <div
                    class="warp-list"
                    v-for="(PositionCondition,
                    PositionConditionIndex) in postcondition.PositionCondition"
                    :key="PositionConditionIndex"
                  >
                    <div class="warp-list-item list-field-more">
                      {{ PositionCondition.DataField }}
                    </div>
                    <div class="warp-list-item">
                      {{ PositionCondition.Operator }}
                    </div>
                    <div class="warp-list-item">
                      {{ PositionCondition.Value }}
                    </div>
                    <div class="warp-list-item">
                      {{ AndOrObject[PositionCondition.AndOr] }}
                    </div>
                  </div>
                </div>

                <div class="right-warp-list">
                  <div
                    class="warp-list"
                    v-for="(PositionCount,
                    PositionCountIndex) in postcondition.PositionCount"
                    :key="PositionCountIndex"
                  >
                    <div class="warp-list-item list-field-more">
                      {{ PositionCount.ResultCountItem }}
                    </div>
                    <div class="warp-list-item">
                      {{ PositionCount.ResultCountType }}
                    </div>
                    <div class="warp-list-item right-head-num">
                      <a-tooltip
                        placement="topLeft"
                        :title="PositionCount.Result"
                        arrow-point-at-center
                      >
                        {{ PositionCount.Result }}
                      </a-tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div style="margin-top: 5px; color: red;">属性</div>
              <div style="display: flex;">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    margin-right: 10px;
                  "
                  v-for="(attrItem, attrKey) in item.attr"
                  :key="attrKey"
                >
                  <a-tag color="blue"> {{ attrKey }}:{{ attrItem }}</a-tag>
                  <!--                                    <div v-for="(attrValue,attrKey) in attrItem">-->

                  <!--                                       -->
                  <!--                                        &lt;!&ndash;                                        <span>{{attrValue}}</span>&ndash;&gt;-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>

            <div class="right-add" v-show="positionId" @click="addPosition">
              {{ new_level ? '编辑' : '添加' }}绩效项目
            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据项目" force-render>
        <div
          class="data-field"
          :style="
            'width:' +
            ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
            'px;'
          "
        >
          <div class="data-search" style="display: flex; align-items: center;">
            <a-select
              style="width: 200px; margin-right: 10px;"
              @change="changeType"
              placeholder="请选择类型"
            >
              <a-select-option value="">所有</a-select-option>
              <a-select-option value="1">个人</a-select-option>
              <a-select-option value="2">中心</a-select-option>
              <a-select-option value="3">绩效</a-select-option>
              <a-select-option value="4">条件</a-select-option>
              <a-select-option value="5">计算项</a-select-option>
            </a-select>
            <a-input
              style="width: 200px; margin-right: 10px;"
              v-model="dataSearch"
            />
            <a-button style="margin-right: 20px;" @click="dataEmpty()"
              >重置条件</a-button
            >
            <a-button
              type="primary"
              style="margin-right: 20px;"
              @click="_dataSearch"
              >查询</a-button
            >

            <a-button @click="() => dataModal(true)" type="primary"
              >添加</a-button
            >
            <a-tag
              style="cursor: pointer; margin-left: 10px;"
              color="orange"
              v-if="dataTips"
              @click="_noticeModal(true)"
              >数据异常
            </a-tag>
            <!--                        <div style="color: red;margin-left: 20px">{{dataTips}}</div>-->
          </div>
          <a-table
            :style="
              'width:' +
              ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
              'px;'
            "
            :pagination="false"
            :columns="columns"
            :data-source="dataList"
            bordered
          >
            <a slot="Person" slot-scope="text, record">
              <a-icon v-if="text == 1" style="color: #1890ff;" type="check" />
            </a>
            <a slot="School" slot-scope="text, record">
              <a-icon v-if="text == 1" style="color: #1890ff;" type="check" />
            </a>
            <a slot="Performance" slot-scope="text, record">
              <a-icon v-if="text == 1" style="color: #1890ff;" type="check" />
            </a>
            <a slot="Condition" slot-scope="text, record">
              <a-icon v-if="text == 1" style="color: #1890ff;" type="check" />
            </a>
            <a slot="Count" slot-scope="text, record">
              <a-icon v-if="text == 1" style="color: #1890ff;" type="check" />
            </a>

            <a slot="Id" slot-scope="text, record">
              <a-popconfirm
                placement="bottom"
                cancelText="取消"
                okText="确定"
                @confirm="dataDel(record.Id)"
                @cancel="maxClassify = -1"
              >
                <template slot="title">
                  <p>确定删除么？</p>
                </template>
                <i class="iconfont icon-shanchu-"></i>
              </a-popconfirm>
              <i
                style="margin-left: 10px;"
                @click="dataSave(record)"
                class="iconfont icon-bianji"
              ></i>
            </a>
          </a-table>
          <a-pagination
            v-if="total"
            v-model="dataPage"
            @change="ChangePage"
            :pageSize="dataPageSize"
            :total="total"
            style="margin-top: 20px; text-align: right;"
          />
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="工资单">
        <div
          class="salary"
          :style="
            'width:' +
            ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
            'px;'
          "
        >
          <div
            class="salary-search"
            style="margin-bottom: 20px; display: flex;"
          >
            <a-tree-select
              style="width: 200px; margin-right: 10px;"
              :maxTagCount="1"
              :treeData="treeDataSchool"
              v-model="DepartmentId"
              treeNodeFilterProp="title"
              show-search
              allow-clear
              @change="onChangeSchool"
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择部门"
            />
            <a-month-picker
              v-model="salaryMonth"
              @change="changeSalaryMonth"
              style="margin-right: 10px;"
              :format="monthFormat"
            />
            <a-input
              style="width: 200px; margin-right: 10px;"
              v-model="salarySearch"
            />
            <a-button style="margin-right: 20px;" @click="salaryEmpty()"
              >重置条件</a-button
            >
            <a-button type="primary" @click="_salarySearch">查询</a-button>

            <a-popconfirm
              placement="bottom"
              cancelText="取消"
              okText="确定"
              @confirm="_salarydown()"
              @cancel="maxClassify = -1"
            >
              <template slot="title">
                <p>你确定要下载嘛？</p>
              </template>
              <a-button type="primary" style="margin-left: 20px;"
                >下载</a-button
              >
            </a-popconfirm>
            <div style="flex: 1; text-align: right;">
              <a-popconfirm
                placement="bottom"
                cancelText="取消"
                okText="确定"
                @confirm="create_payslip()"
                @cancel="maxClassify = -1"
              >
                <template slot="title">
                  <p>此操作将会生成工资单，请再次确认是否执行？</p>
                </template>
                <a-button type="primary">生成工资单</a-button>
              </a-popconfirm>
            </div>
          </div>
          <div
            :style="
              'width:' +
              ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
              'px;'
            "
          >
            <a-table
              :pagination="false"
              :scroll="{ x: 1800 }"
              :columns="salarycolumns"
              :data-source="salaryList"
              bordered
            >
              <a slot="Status" slot-scope="text, record">
                <span v-if="text == 1">已确认</span>
                <a-popconfirm
                  v-if="text == 0"
                  placement="bottom"
                  cancelText="取消"
                  okText="确定"
                  @confirm="changeStatus(record.Id, 1)"
                  @cancel="maxClassify = -1"
                >
                  <template slot="title">
                    <p>你确定要更改状态为已确认嘛？</p>
                  </template>
                  <span style="color: #096dd9;">未确认</span>
                </a-popconfirm>
              </a>
              <a slot="Id" slot-scope="text, record">
                <a-dropdown :trigger="['click']">
                  <a
                    class="ant-dropdown-link"
                    @click="(e) => e.preventDefault()"
                  >
                    <a-icon
                      type="ellipsis"
                      style="font-size: 28px; font-weight: 800;"
                    />
                  </a>
                  <a-menu
                    slot="overlay"
                    @click="listChangeAction($event, record)"
                  >
                    <a-menu-item key="0">
                      <span>生成工资单</span>
                    </a-menu-item>
                    <a-menu-item key="1">
                      <span>修改</span>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <!--                                <span style="color: #096dd9;cursor: pointer" @click="listSave(record)">修改</span>-->
              </a>
            </a-table>
            <a-pagination
              v-if="salaryTotal"
              :total="salaryTotal"
              :page-size="salaryPageSize"
              @change="ChangeSalaryPage"
              style="margin-top: 20px; text-align: right;"
            >
            </a-pagination>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="基础数据">
        <div class="base">
          <div
            :style="
              'width:' +
              ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
              'px;'
            "
            class="salary-search"
            style="margin-bottom: 20px; display: flex; align-items: center;"
          >
            <a-select
              style="width: 120px; margin-right: 10px;"
              v-model="baseType"
              @change="changeBaseType"
              placeholder="请选择类型"
            >
              <a-select-option value="1">个人</a-select-option>
              <a-select-option value="2">中心</a-select-option>
            </a-select>

            <a-tree-select
              style="width: 200px; margin-right: 10px;"
              :maxTagCount="1"
              :treeData="treeDataSchool"
              v-model="baseDepartmentId"
              treeNodeFilterProp="title"
              show-search
              allow-clear
              @change="baseChangeSchool"
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择部门"
            />

            <a-month-picker
              v-model="baseMonth"
              @change="changeBaseMonth"
              style="margin-right: 10px;"
              :format="monthFormat"
            />

            <a-input
              style="width: 200px; margin-right: 10px;"
              v-model="baseSearch"
            />
            <a-button style="margin-right: 20px;" @click="baseEmpty()"
              >重置条件</a-button
            >
            <a-button type="primary" @click="_baseSearch">查询</a-button>
            <a-popconfirm
              placement="bottom"
              cancelText="取消"
              okText="确定"
              @confirm="_basedown()"
              @cancel="maxClassify = -1"
            >
              <template slot="title">
                <p>你确定要下载嘛？</p>
              </template>
              <a-button type="primary" style="margin-left: 20px;"
                >下载</a-button
              >
            </a-popconfirm>

            <a-tag
              style="cursor: pointer; margin-left: 10px;"
              color="orange"
              v-if="dataTips || notice"
              @click="_noticeModal(true)"
              >数据异常
            </a-tag>
            <div style="flex: 1; text-align: right;">
              <a-popconfirm
                placement="bottom"
                cancelText="取消"
                okText="确定"
                @confirm="_baseUpdate()"
                @cancel="maxClassify = -1"
              >
                <template slot="title">
                  <p>此操作将会重新更新数据，请再次确认是否执行？</p>
                </template>
                <a-button type="primary">更新数据</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>
        <div
          :style="
            'width:' +
            ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
            'px;'
          "
        >
          <a-table
            :pagination="false"
            :scroll="{ x: tableWidth }"
            :columns="baseColumns"
            :data-source="baseList"
            bordered
          >
            <a slot="Status" slot-scope="text, record">
              <span v-if="text == 1">已确认</span>
              <a-popconfirm
                v-if="text == 0"
                placement="bottom"
                cancelText="取消"
                okText="确定"
                @confirm="changeStatus(record.Id)"
                @cancel="maxClassify = -1"
              >
                <template slot="title">
                  <p>你确定要更改状态为已确认嘛？</p>
                </template>
                <span>未确认</span>
              </a-popconfirm>
            </a>
            <a slot="Id" slot-scope="text, record">
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  <a-icon
                    type="ellipsis"
                    style="font-size: 28px; font-weight: 800;"
                  />
                </a>
                <a-menu
                  slot="overlay"
                  @click="baseChangeAction($event, record, 'school')"
                >
                  <a-menu-item key="0">
                    <span>更新</span>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <span>修改</span>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <!--                            <span v-else style="color: #096dd9;cursor: pointer"-->
              <!--                                  @click="listSave(record,'school')">修改</span>-->
            </a>
          </a-table>
          <a-pagination
            v-if="baseTotal"
            :total="baseTotal"
            :page-size="basePageSize"
            @change="ChangeBasePage"
            style="margin-top: 20px; text-align: right;"
          >
          </a-pagination>
        </div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="退费清算">
        <div
          :style="
            'width:' +
            ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
            'px;'
          "
        >
          <div
            class="salary-search"
            style="margin-bottom: 20px; display: flex; align-items: center;"
          >
            <a-select
              style="width: 120px; margin-right: 10px;"
              v-model="refundType"
              @change="changeRefundType"
              placeholder="请选择类型"
            >
              <a-select-option value="1">课程退费</a-select-option>
            </a-select>

            <a-tree-select
              style="width: 200px; margin-right: 10px;"
              :maxTagCount="1"
              :treeData="treeDataSchool"
              v-model="refundDepartmentId"
              treeNodeFilterProp="title"
              show-search
              allow-clear
              @change="refundChangeSchool"
              :showCheckedStrategy="SHOW_PARENT"
              placeholder="请选择部门"
            />

            <a-input
              style="width: 200px; margin-right: 10px;"
              v-model="refundSearch"
            />
            <a-button style="margin-right: 20px;" @click="refundEmpty()"
              >重置条件</a-button
            >
            <a-button type="primary" @click="_refundSearch">查询</a-button>
            <a-popconfirm
              placement="bottom"
              cancelText="取消"
              okText="确定"
              @confirm="_refundDown()"
              @cancel="maxClassify = -1"
            >
              <template slot="title">
                <p>你确定要下载嘛？</p>
              </template>
              <a-button type="primary" style="margin-left: 20px;"
                >下载</a-button
              >
            </a-popconfirm>
          </div>

          <a-table
            :pagination="false"
            :columns="refundColumns"
            :data-source="refundData"
          >
            <template slot="Id" slot-scope="text, record">
              <a-dropdown :trigger="['click']">
                <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                  <a-icon
                    type="ellipsis"
                    style="font-size: 28px; font-weight: 800;"
                  />
                </a>
                <a-menu
                  slot="overlay"
                  @click="refundChangeAction($event, record)"
                >
                  <a-menu-item key="0">
                    <span>查看记录</span>
                  </a-menu-item>
                  <a-menu-item key="1">
                    <span>修改</span>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-table>
          <a-pagination
            v-if="refundTotal"
            :total="refundTotal"
            :page-size="refundPageSize"
            @change="ChangeRefundPage"
            style="margin-top: 20px; text-align: right;"
          >
          </a-pagination>
        </div>
      </a-tab-pane>

      <a-tab-pane key="6" tab="社保个税">
        <div class="salary" style="width: 100%;">
          <div class="salary-search" style="margin-bottom: 20px;">
            <a-select
              v-model="importType"
              style="width: 200px; margin-right: 10px;"
              placeholder="请选择类型"
              @change="changeImport($event, 'type')"
            >
              <a-select-option
                v-for="(item, index) in importTypeArr"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-month-picker
              v-model="importMonth"
              @change="changeImport"
              style="margin-right: 10px;"
              :format="monthFormat"
            />
            <a-input
              style="width: 200px; margin-right: 10px;"
              v-model="importKeyWord"
            />
            <a-button style="margin-right: 20px;" @click="importEmpty()"
              >重置条件</a-button
            >
            <a-button type="primary" @click="changeImport('search', 'search')"
              >查询</a-button
            >
            <a-button
              type="primary"
              style="position: relative; margin-left: 10px;"
            >
              上传
              <input
                type="file"
                class="upload-bth"
                id="excel"
                @change="excelFn"
              />
            </a-button>
            <a-popconfirm
              placement="bottom"
              cancelText="取消"
              okText="确定"
              @confirm="_ImportDown()"
              @cancel="maxClassify = -1"
            >
              <template slot="title">
                <p>你确定要下载嘛？</p>
              </template>
              <a-button type="primary" style="margin-left: 20px;"
                >下载</a-button
              >
            </a-popconfirm>
          </div>
          <div
            :style="
              'width:' +
              ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
              'px;'
            "
          >
            <a-table
              :pagination="false"
              :scroll="{ x: 1800 }"
              :columns="importColumns"
              :data-source="importData"
              bordered
            >
              <a slot="Type" slot-scope="text, record">
                <span>{{ importTypeObj[text] }}</span>
              </a>
              <a slot="Id" slot-scope="text, record">
                <span
                  style="color: #096dd9; cursor: pointer;"
                  @click="importSave(record, 'salary')"
                  >修改</span
                >
              </a>
            </a-table>
            <a-pagination
              v-if="importTotal"
              :total="importTotal"
              :page-size="importPageSize"
              @change="ChangeImportPage"
              style="margin-top: 20px; text-align: right;"
            >
            </a-pagination>
          </div>
        </div>
      </a-tab-pane>

      <!--职位匹配-->
      <a-tab-pane key="8" tab="职位匹配">
        <div class="salary" style="width: 100%;">
          <positionMatch v-if="tabKey == 8"></positionMatch>
        </div>
      </a-tab-pane>

      <a-tab-pane key="7" tab="其它">
        <div
          class="other"
          :style="
            'width:' +
            ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
            'px;'
          "
        >
          <a-card
            v-for="(item, index) in otherList"
            :key="index"
            class="other-list"
            :title="item.Name"
            style="width: 300px;"
          >
            <a style="color: #40a9ff;" slot="extra" @click="_otherUrl(item.Url)"
              >更新</a
            >
            <p>最后更新:{{ item.LastUpdateTime }}</p>
          </a-card>
        </div>
      </a-tab-pane>
      <a-icon
        style="font-size: 20px; cursor: pointer;"
        slot="tabBarExtraContent"
        type="info-circle"
        @click="
          _pageContent(true, '31afeb454459dc0ab7920d8321736a48', 'drawer')
        "
      />
    </a-tabs>
    <!--数据项目操作 start-->
    <a-drawer
      :title="FieldName ? FieldName : '添加'"
      width="600"
      :closable="false"
      :visible="dataStatus"
      @close="() => dataModal(false)"
    >
      <div class="data-action">
        <div class="data-group">
          <div class="data-label">项目名称</div>
          <a-input
            :disabled="EditableStatus"
            class="data-item"
            v-model="FieldName"
          />
        </div>
        <div class="data-group">
          <div class="data-label">个人</div>
          <a-checkbox
            :checked="Person"
            @change="changeCheck($event, 'Person')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">中心</div>
          <a-checkbox
            :checked="School"
            @change="changeCheck($event, 'School')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">绩效</div>
          <a-checkbox
            :checked="Performance"
            @change="changeCheck($event, 'Performance')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">条件</div>
          <a-checkbox
            :checked="Condition"
            @change="changeCheck($event, 'Condition')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">计算项</div>
          <a-checkbox
            :checked="Count"
            @change="changeCheck($event, 'Count')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">可编辑</div>
          <a-checkbox
            :checked="Editable"
            @change="changeCheck($event, 'Editable')"
          ></a-checkbox>
        </div>
        <div class="data-group">
          <div class="data-label">注释</div>
          <a-input class="data-item" v-model="Remark" />
        </div>
        <div class="data-group">
          <div class="data-label">关联职位</div>
          <a-select
            v-model="PositionItems"
            :default-value="PositionItems"
            class="data-item"
            mode="multiple"
            style="width: 100%;"
            placeholder="请选择关联职位"
            @change="selectPositionItems"
          >
            <a-select-option
              v-for="(item, index) in positionList"
              :key="index"
              :value="item.Position"
              >{{ item.Position }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => dataModal(false)">
          取消
        </a-button>
        <a-button type="primary" @click="dataAction">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!--        数据项目操作end-->

    <!--        列表修改start-->
    <a-drawer
      :title="listType == 'salary' ? '工资单修改' : '基础数据修改'"
      width="800"
      :closable="false"
      :visible="liastStatus"
      @close="() => listModal(false)"
    >
      <div class="data-action">
        <div class="data-group" v-for="(item, index) in listData" :key="index">
          <div class="data-label">{{ item.name }}</div>
          <a-input
            @change="changeVal()"
            :disabled="listArr.indexOf(item.name) == -1 ? false : true"
            class="data-item"
            v-model="listObj[item.field]"
          />
          <!--                    :value="item.value"-->
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => listModal(false)">
          取消
        </a-button>
        <a-button type="primary" @click="listAction">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!--列表修改end-->
    <!--        绩效操作start-->
    <a-drawer
      :title="positionName"
      width="1400"
      :closable="false"
      :visible="visible"
      @close="_saveCclose"
    >
      <div style="margin-bottom: 60px;">
        <performancAction
          v-if="visible"
          :PositionName="positionName"
          :positionId="positionId"
          :Month="Month"
          :treeDataSchool="treeDataSchool"
          :OrgIds="OrgId"
          :Level="new_level"
          :isRead="isRead"
          @visible="_close"
        ></performancAction>
      </div>
    </a-drawer>
    <!--绩效操作end-->
    <!--        退费清算修改-->
    <a-drawer
      title="修改"
      width="600"
      :closable="false"
      :visible="refundStatus"
      @close="() => refundModal(false)"
    >
      <div class="data-action">
        <div class="data-group">
          <div class="data-label">退费金额</div>
          <a-input class="data-item" v-model="Amount" />
        </div>
        <div class="data-group">
          <div class="data-label">修改原因</div>
          <a-input class="data-item" v-model="Reason" />
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => refundModal(false)">
          取消
        </a-button>
        <a-button type="primary" @click="refundAction">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!--        退费清算end-->
    <!--        退费金额操作记录start-->
    <a-drawer
      title="操作记录"
      width="800"
      :closable="false"
      :visible="refundLogStatus"
      @close="() => refundLogModal(false)"
    >
      <div style="margin-bottom: 60px;">
        <a-table
          :pagination="false"
          :columns="refundLogColumns"
          :data-source="refundLogData"
        >
        </a-table>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button
          style="marginright: 8px;"
          @click="() => refundLogModal(false)"
        >
          关闭
        </a-button>
        <!--                <a-button type="primary" @click="refundAction">-->
        <!--                    确定-->
        <!--                </a-button>-->
      </div>
    </a-drawer>
    <!--        退费金额操作记录end-->
    <!--        数据异常start-->
    <a-drawer
      title="数据异常"
      width="600"
      :closable="false"
      :visible="noticeStatus"
      @close="() => _noticeModal(false)"
    >
      <div style="margin-bottom: 60px;">
        <div style="color: red; margin-left: 20px;">
          <div style="margin-bottom: 5px;">{{ dataTips }}</div>
          <div style="color: #d38080;">{{ notice }}</div>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => _noticeModal(false)">
          关闭
        </a-button>
        <!--                <a-button type="primary" @click="refundAction">-->
        <!--                    确定-->
        <!--                </a-button>-->
      </div>
    </a-drawer>
    <!--        数据异常end-->
    <!--        社保个税start-->
    <a-drawer
      :title="listType == 'salary' ? '工资单修改' : '基础数据修改'"
      width="800"
      :closable="false"
      :visible="importStatus"
      @close="() => importModal(false)"
    >
      <div class="data-action">
        <div
          class="data-group"
          v-for="(item, index) in importList"
          :key="index"
        >
          <div class="data-label">{{ item.name }}</div>
          <a-input
            :disabled="importArr.indexOf(item.field) == -1 ? false : true"
            class="data-item"
            v-model="importObj[item.field]"
          />
          <!--                    :value="item.field=='Type'?importTypeObj[item.value]:item.value"-->
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => importModal(false)">
          取消
        </a-button>
        <a-button type="primary" @click="importAction">
          确定
        </a-button>
      </div>
    </a-drawer>
    <!--        社保个税end-->
    <!--        绩效职位start-->
    <a-drawer
      title="添加职位"
      width="600"
      :closable="false"
      :visible="addStatus"
      @close="() => addStatusModal(false)"
    >
      <div class="data-action">
        <div class="data-group">
          <div class="data-label">职位名称</div>
          <a-input class="data-item" v-model="Position" />
        </div>
        <div class="data-group">
          <div class="data-label">职位级别</div>
          <div class="data-item">
            <a-input v-model="Level" />
            <div style="color: red; margin-top: 5px;">
              职位级别，直接中文用#隔开
            </div>
          </div>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button
          style="marginright: 8px;"
          @click="() => addStatusModal(false)"
        >
          取消
        </a-button>
        <a-button type="primary" @click="toAction">
          确定
        </a-button>
      </div>
    </a-drawer>

    <a-drawer
      title="复制绩效"
      width="600"
      :closable="false"
      :visible="copyItem"
      @close="() => copyItemModal(false)"
    >
      <div class="data-action">
        <div class="data-group">
          <div class="data-label">月份</div>
          <a-month-picker
            style="width: 200px;"
            v-model="copyMonth"
            @change="changeCopyMonth"
            :format="monthFormat"
          />
        </div>

        <div class="data-group">
          <div class="data-label">职位</div>
          <a-select
            show-search
            style="width: 200px;"
            @change="position_detail_copy"
            v-model="positionNameCopy"
            placeholder="请选择职位"
          >
            <a-select-option
              v-for="(item, index) in positionList"
              :key="index"
              :value="item.Position"
              >{{ item.Position }}</a-select-option
            >
          </a-select>
        </div>
        <div class="data-group">
          <div class="data-label">部门</div>
          <a-tree-select
            :defaultValue="Level"
            show-search
            style="width: 200px; margin-right: 10px;"
            :treeData="level_list"
            placeholder="请选择部门"
            allow-clear
            @change="changeCopy"
          >
          </a-tree-select>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
      >
        <a-button style="marginright: 8px;" @click="() => copyItemModal(false)">
          取消
        </a-button>

        <a-popconfirm
          placement="bottom"
          cancelText="取消"
          okText="确定"
          @confirm="doCopy"
          @cancel="maxClassify = -1"
        >
          <template slot="title">
            <p>确定要复制吗？</p>
          </template>
          <a-button type="primary" style="margin-left: 20px;">确定</a-button>
        </a-popconfirm>
      </div>
    </a-drawer>

    <!--        绩效职位end-->
    <pageCotent
      v-if="pageStatus"
      :Id="pageId"
      :ModelType="ModelType"
      @_coloseTips="_pageContent"
    ></pageCotent>
  </div>
</template>

<script>
import performancAction from 'pages/User/performancAction'
import positionMatch from 'pages/User/positionMatch'
import moment from 'moment'
import { TreeSelect } from 'ant-design-vue'
import { mapState } from 'vuex'

import pageCotent from 'pages/PageAdmin/pageContent'
export default {
  components: { performancAction, positionMatch, pageCotent },
  name: 'performanc',
  data() {
    return {
      positionList: [],
      positionInfo: '',
      tableList: [],
      positionIndex: -1,
      visible: false,
      positionName: undefined,
      positionNameCopy: undefined,
      Position: '',
      Level: undefined,
      addStatus: false,
      copyItem: false,
      AndOrObject: { '0': '无', '1': '并且', '2': '或者', '3': '并列计算' },
      monthFormat: 'YYYY-MM',
      Month: this.getMonth(0),
      copyMonth: this.getMonth(0),
      copyOneId: '',
      positionId: '',
      tableColumns: [],
      orgList: [],
      OrgId: '',
      OrgIdCopy: '',
      //数据项目 start
      dataList: [],

      columns: [
        {
          title: '项目名称',
          dataIndex: 'FieldName',
          width: 120,
          align: 'center',
        },
        {
          title: '个人',
          dataIndex: 'Person',
          scopedSlots: { customRender: 'Person' },
          width: 120,
          align: 'center',
        },
        {
          title: '中心',
          dataIndex: 'School',
          scopedSlots: { customRender: 'School' },
          width: 120,
          align: 'center',
        },
        {
          title: '绩效',
          dataIndex: 'Performance',
          scopedSlots: { customRender: 'Performance' },
          width: 120,
          align: 'center',
        },
        {
          title: '条件',
          dataIndex: 'Condition',
          scopedSlots: { customRender: 'Condition' },
          width: 120,
          align: 'center',
        },
        {
          title: '计算项',
          dataIndex: 'Count',
          scopedSlots: { customRender: 'Count' },
          width: 120,
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'Id',
          scopedSlots: { customRender: 'Id' },
          width: 120,
          align: 'center',
        },
      ],
      Type: '',
      dataPage: 1,
      dataPageSize: 10,
      dataStatus: false,
      total: 0,
      Id: '',
      FieldName: '',
      Person: false,
      School: false,
      Performance: false,
      Condition: false,
      Count: false,
      Editable: false,
      EditableStatus: false,
      Remark: '',
      dataTips: '',
      dataSearch: '',
      PositionItems: [],

      //数据项目 end
      //工资单start
      salaryList: [],
      salaryTotal: '',
      salaryPage: 1,
      salaryPageSize: 10,
      salaryMonth: this.getMonth(0),
      DepartmentId: [],
      salarycolumns: [],
      treeDataSchool: [],
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      pageWidth: document.documentElement.clientWidth - 200,
      salarySearch: '',
      //工资单end
      //基础数据start
      baseMonth: this.getMonth(0),
      basePage: 1,
      baseDepartmentId: [],
      baseList: [],
      baseColumns: [],
      baseType: '2',
      baseTotal: '',
      basePageSize: 10,
      baseSearch: '',
      notice: '',
      //基础数据end
      //列表修改start
      liastStatus: false,
      listId: '',
      listData: [],
      listArr: ['姓名', '工号', '月份', '中心名称', '中心Id', '部门', 'Status'],
      listType: '',
      listObj: {},
      //列表修改end
      tableWidth: 1800,
      //退费清算start
      refundColumns: [
        {
          title: '姓名',
          dataIndex: 'UserName',
          align: 'center',
          key: '0',
        },
        {
          title: '退费金额',
          dataIndex: 'RefundAmount',

          align: 'center',
          scopedSlots: { customRender: 'RefundAmount' },
          key: '1',
        },
        {
          title: '所属部门',
          dataIndex: 'DepartmentStr',
          align: 'center',
          key: '2',
        },
        {
          title: '操作',
          dataIndex: 'Id',

          align: 'center',
          scopedSlots: { customRender: 'Id' },
          key: '3',
        },
      ],
      refundData: [],
      refundType: '1',
      refundSearch: '',
      refundPage: 1,
      refundTotal: '',
      refundPageSize: 10,
      refundDepartmentId: [],
      refundStatus: false,
      refundId: '',
      Amount: '',
      Reason: '',
      //退费清算end
      //退费清算操作记录start
      refundLogStatus: false,
      refundLogData: [],
      refundLogColumns: [
        {
          title: '操作人',
          dataIndex: 'CreateUserName',
          align: 'center',
          key: '0',
        },
        {
          title: '修改原因',
          dataIndex: 'Reason',
          align: 'center',
          key: '3',
        },

        {
          title: '修改值',
          dataIndex: 'RefundAmount',
          align: 'center',
          key: '1',
        },
        {
          title: '操作时间',
          dataIndex: 'CreateTime',
          align: 'center',
          key: '2',
        },
      ],
      //退费清算操作记录end
      noticeStatus: false,
      //社保个税start
      importData: [],
      importColumns: [],
      importType: undefined,
      importMonth: this.getMonth(),
      importPage: 1,
      importPageSize: 10,
      importTotal: '',
      importStatus: false,
      importKeyWord: undefined,
      importTypeArr: [],
      importTypeObj: {},
      importId: '',
      importObj: {},
      importArr: [],
      importList: [],
      //社保个税end
      //其它start
      otherList: [],

      //其它end
      pageId: '',
      ModelType: '',
      pageStatus: false,
      new_level: undefined,
      level_list: [],
      copy_level: '',
      tabKey: 1,
      isRead: false,
    }
  },
  computed: {
    ...mapState({
      showNav: (state) => state.app.showNav,
    }),
  },

  created() {
    this.position_list()
    this.$axios.get(8012, {}, (res) => {
      if (res.data.code == 1) {
        this.treeDataSchool = res.data.data
      } else {
        this.$message.error(res.data.msg)
      }
    })
  },
  methods: {
    _pageContent(status, id, type) {
      this.pageStatus = status
      this.pageId = id
      this.ModelType = type
    },
    changeVal() {},
    _noticeModal(val) {
      this.noticeStatus = val
    },
    getMonth() {
      var myDate = new Date()
      let year = myDate.getFullYear()
      let month = myDate.getMonth() + 1
      month = month > 9 ? month : '0' + month
      let val = year + '-' + month
      // console.log(val);
      return val
    },
    moment,
    callback(key) {
      this.tabKey = key
      if (key == 2) {
        //数据项目
        this.baseType = ''
        this.data_field_list()
        this.check_field()
      } else if (key == 3) {
        //工资单
        this.salaryPage = 1
        this.salary_list()
      } else if (key == 4) {
        //基础数据
        this.baseType = '2'
        this.basePage = 1
        this.base_list()
        this.check_field()
      } else if (key == 5) {
        //退费清算
        this.refundPage = 1
        this.refundList()
      } else if (key == 6) {
        this.importPage = 1
        this._import()
      } else if (key == 7) {
        //其它
        this.getOtherList()
      }
    },
    //其它start
    _otherUrl(val) {
      if (val) {
        window.open(val)
      }
    },
    getOtherList() {
      let self = this
      this.$message.loading('加载中..', 0)
      this.$axios.get(129462, {}, (res) => {
        this.$message.destroy()
        if (res.data.code == 1) {
          self.otherList = res.data.data
        } else {
          self.otherList = []
          this.$message.error(res.data.msg)
        }
      })
    },
    //其它end
    //  社保个税start
    excelFn(e) {
      let self = this

      let files = document.getElementById('excel').files
      this.$message.loading('上传中', 0)
      this.__uploadFile__(files).then((res) => {
        this.$message.destroy()
        if (res.length > 0) {
          self.$message.success('上传成功')
          this.$message.loading('导入中....', 0)
          self.$axios.post(
            129461,
            {
              url: res[0].FileUrl,
              type: self.importType || '',
            },
            (ress) => {
              this.$message.destroy()
              if (ress.data.code == 1) {
                self.$message.success('导入成功')
                self._import()
              } else {
                self.$message.success(ress.data.msg)
              }
            }
          )
        } else {
          this.$message.error('上传文件失败!')
        }
      })
    },
    importSave(data) {
      this.importId = data.Id
      let arr = ['Id']
      this.importObj = {}
      this.importList = []
      for (let key in data) {
        if (arr.indexOf(key) == -1) {
          let obj = {}
          if (key == 'Type') {
            obj.name = '类型'
          } else {
            obj.name = key
          }
          obj.field = key
          obj.value = data[key]
          this.importList.push(obj)
          this.importObj[key] = data[key]
        }
      }

      this.importModal(true)
    },
    importAction() {
      let self = this
      this.$message.loading('加载中...', 0)
      this.$axios.post(
        129460,
        {
          Id: self.importId,
          data: JSON.stringify(self.importObj),
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.importModal(false)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    importModal(val) {
      this.importStatus = val
      if (!val) {
        this.importId = ''
        this._import()
      }
    },
    importEmpty() {
      this.importPage = 1
      this.importMonth = undefined
      this.importType = undefined
      this.importKeyWord = undefined
      this._import()
    },
    ChangeImportPage(val) {
      this.importPage = val
      this._import()
    },
    changeImport(val, name) {
      if (name != 'type' && name != 'search') {
        this.importMonth = name
      }
      // console.log(this.importType)
      this.importPage = 1
      this._import()
    },
    _import() {
      let self = this
      self.importColumns = []
      self.importTypeArr = ''
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        129459,
        {
          Type: self.importType || '',
          Month: self.importMonth,
          Page: self.importPage,
          PageSize: self.importPageSize,
          KeyWord: self.importKeyWord || '',
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.importData = res.data.data
            let importColumns = res.data.data[0]
            let arr = ['Id']
            for (let key in importColumns) {
              if (arr.indexOf(key) == -1) {
                let obj = {
                  title: '',
                  fixed: '',
                  dataIndex: '',
                  scopedSlots: { customRender: '' },
                }
                if (key == 'Type') {
                  obj.title = '类型'
                } else {
                  obj.title = key
                }
                obj.dataIndex = key
                obj.scopedSlots.customRender = key
                self.importColumns.push(obj)
              }
            }
            let idObj = {
              title: '操作',
              fixed: '',
              dataIndex: 'Id',
              scopedSlots: { customRender: 'Id' },
            }
            self.importColumns.push(idObj)
            for (let i = 0; i < self.importColumns.length; i++) {
              if (i < 1) {
                self.importColumns[i].fixed = 'left'
                self.importColumns[i].width = 100
                self.importColumns[i].ellipsis = true
              } else {
                if (self.importColumns.length == i) {
                  self.importColumns[i].width = ''
                } else {
                  if (self.importColumns.length >= 14) {
                    self.importColumns[i].width = 80
                  }
                }
              }

              if (i + 1 == self.importColumns.length) {
                self.importColumns[i].fixed = 'right'
                self.importColumns[i].ellipsis = true
                self.importColumns[i].width = 100
              }
            }
            let type = res.data.type
            for (let i = 0; i < type.length; i++) {
              self.importTypeObj[type[i].value] = type[i].name
            }
            self.importTypeArr = res.data.type
            self.importTotal = res.data.count
            if (res.data.hasOwnProperty('importArr')) {
              self.importArr = res.data.importArr
            }
          } else {
            self.importTypeArr = res.data.type
            self.importData = []
            self.importColumns = []
            self.importTotal = ''
          }
        }
      )
    },
    _ImportDown() {
      let url =
        'https://sapi.sikegroup.com/v1/performance/import_data_list?tab=download&Type=' +
        this.importType +
        '&Month=' +
        this.importMonth +
        '&KeyWord=' +
        this.importKeyWord
      window.open(url)
    },
    //社保个税end
    //字段检查
    check_field() {
      let self = this
      this.$axios.get(
        129453,
        {
          Type: self.baseType,
        },
        (res) => {
          if (res.data.code == 1) {
            //正常
            self.dataTips = ''
          } else {
            self.dataTips = res.data.msg
          }
        }
      )
    },
    //退费金额修改start
    refundModal(val) {
      this.refundStatus = val
      if (!val) {
        this.refundId = ''
        this.Amount = ''
        this.Reason = ''
      }
    },
    refundAction() {
      let self = this
      if (!this.Reason) {
        this.$message.error('请输入修改原因')
        return false
      }
      this.$message.loading('操作中...', 0)
      this.$axios.get(
        129455,
        {
          Id: self.refundId,
          Amount: self.Amount,
          Reason: self.Reason,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.refundList()
            self.refundModal(false)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    //退费金额修改end
    //退费清算start
    refundLogModal(val) {
      this.refundLogStatus = val
      if (!val) {
        this.refundLogData = []
        this.refundId = ''
      }
    },
    refundLogList() {
      let self = this
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        129456,
        {
          Id: self.refundId,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.refundLogData = res.data.data
          } else {
            self.refundLogData = []
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _refundSearch() {
      this.refundPage = 1
      this.refundList()
    },
    refundEmpty() {
      this.refundSearch = ''
      this.refundPage = 1
      this.refundType = '1'
      this.refundDepartmentId = []
      this.refundList()
    },
    refundChangeSchool(value) {
      this.refundPage = 1
      this.refundDepartmentId = []

      this.refundDepartmentId.push(value)
      this.refundList()
    },
    changeRefundType(val) {
      this.refundPage = 1
      this.refundType = val
      this.refundList()
    },
    ChangeRefundPage(val) {
      this.refundPage = val
      this.refundList()
    },
    refundChangeAction(key, val) {
      this.refundId = val.Id
      this.Amount = val.RefundAmount
      if (key.key == 1) {
        this.refundModal(true)
      } else {
        this.refundLogModal(true)
        this.refundLogList()
      }
    },
    refundList() {
      let self = this
      this.$message.loading('加载中....', 0)
      this.$axios.get(
        129454,
        {
          OrgId: self.refundDepartmentId.join(','),
          Page: self.refundPage,
          PageSize: self.refundPageSize,
          Type: self.refundType,
          KeyWord: self.refundSearch,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.refundTotal = res.data.count
            self.refundData = res.data.data
          } else {
            self.refundTotal = ''
            self.refundData = []
            this.$message.error(res.data.msg)
          }
        }
      )
    },

    _refundDown() {
      let url =
        'https://sapi.sikegroup.com/v1/performance/person_refund_list?tab=download&OrgId=' +
        this.refundDepartmentId.join(',') +
        '&Type=' +
        this.refundType +
        '&KeyWord=' +
        this.refundSearch
      window.open(url)
    },
    //退费清算end
    //基础数据start
    baseEmpty() {
      this.basePage = 1
      this.baseSearch = ''
      this.baseDepartmentId = []
      this.baseType = '2'
      this.baseMonth = this.getMonth(0)
      this.base_list()
    },
    _baseSearch() {
      this.basePage = 1
      this.base_list()
    },
    base_list() {
      let self = this
      self.baseColumns = []
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        129445,
        {
          OrgId: self.baseDepartmentId.join(','),
          Page: self.basePage,
          PageSize: self.basePageSize,
          Month: self.baseMonth,
          // Month:'2021-09',
          Type: self.baseType,
          KeyWord: self.baseSearch,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.baseList = res.data.data
            self.baseTotal = res.data.count
            self.notice = res.data.notice
            let column = res.data.column
            let width = 0
            for (let i = 0; i < column.length; i++) {
              let obj = {
                title: '',
                fixed: '',
                dataIndex: '',
                scopedSlots: { customRender: '' },
              }

              if (column[i] == 'Status') {
                obj.title = '状态'
              } else if (column[i] == 'DepartmentStr') {
                obj.title = '部门'
              } else {
                obj.title = column[i]
              }
              if (i < 3) {
                obj.fixed = 'left'
                obj.width = 120
              } else {
                if (self.baseType == 2) {
                  obj.width = 160
                  width += 160
                } else {
                  obj.width = 100
                  width += 100
                }
              }

              // if (i == column.length) {
              //     obj.width = '';
              // }

              obj.dataIndex = column[i]
              obj.scopedSlots.customRender = column[i]
              self.baseColumns.push(obj)
            }
            if (width > 1800) {
              self.tableWidth = width + 80
            }
            let idObj = {
              title: '操作',
              fixed: '',
              dataIndex: 'Id',
              scopedSlots: { customRender: 'Id' },
            }
            self.baseColumns.push(idObj)
            for (let i = 0; i < self.baseColumns.length; i++) {
              if (
                i + 1 == self.baseColumns.length &&
                self.baseColumns.length > 12
              ) {
                self.baseColumns[i].fixed = 'right'
                self.baseColumns[i].width = 120
                self.baseColumns[i].ellipsis = true
              }
            }
          } else {
            self.notice = ''
            self.baseList = []
            self.baseTotal = ''
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _baseUpdate() {
      if (!this.baseMonth) {
        this.$message.error('请先选择月份')
        return false
      }
      // if(this.baseDepartmentId.length==0){
      //     this.$message.error('请先选择部门');
      //     return false;
      // }
      if (this.baseType == 1) {
        //个人
        window.open(
          'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=performance_count&month=' +
            this.baseMonth +
            '&orgid=' +
            this.baseDepartmentId.join(',')
        )
      } else {
        window.open(
          'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=performance_count_school&month=' +
            this.baseMonth +
            '&orgid=' +
            this.baseDepartmentId.join(',')
        )
      }
    },
    _basedown() {
      let url =
        'https://sapi.sikegroup.com/v1/performance/base_data?tab=download&OrgId=' +
        this.baseDepartmentId.join(',') +
        '&Month=' +
        this.baseMonth +
        '&Type=' +
        this.baseType +
        '&KeyWord=' +
        this.baseSearch
      window.open(url)
    },
    ChangeBasePage(val) {
      this.basePage = val
      this.base_list()
    },
    changeBaseType(val) {
      this.basePage = 1
      this.baseType = val
      this.base_list()
      this.check_field()
    },
    changeBaseMonth(val, str) {
      this.basePage = 1
      this.baseMonth = str
      this.base_list()
    },
    baseChangeSchool(value) {
      this.basePage = 1
      this.baseDepartmentId = []
      this.baseDepartmentId.push(value)
      this.base_list()
    },
    //基础数据end
    //列表修改start
    listModal(val) {
      this.liastStatus = val
      if (!val) {
        this.listId = ''
        this.listType = ''
        this.listData = []
        this.listObj = {}
      }
    },
    baseChangeAction(key, val, type) {
      if (key.key == 1) {
        this.listSave(val, type)
      } else {
        let url = ''
        if (this.baseType == 1) {
          //个人
          url =
            'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=performance_count&month=' +
            this.baseMonth +
            '&userid=' +
            val.员工Id
        } else {
          url =
            'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=performance_count_school&month=' +
            this.baseMonth +
            '&schoolid=' +
            val.中心Id
        }
        window.open(url)
      }
    },
    listChangeAction(key, val) {
      if (key.key == 1) {
        this.listSave(val)
      } else {
        window.open(
          'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=create_payslip&month=' +
            this.salaryMonth +
            '&userid=' +
            val.员工ID
        )
      }
    },
    listSave(val, type) {
      if (type == 'salary') {
        this.listType = type
      } else {
        if (this.baseType == 1) {
          this.listType = 'user'
        } else {
          this.listType = 'school'
        }
      }

      let arr = ['Id', '员工ID', '员工Id']
      if (val.hasOwnProperty('Id')) {
        this.listId = val.Id
      } else {
        this.$message.error('数据不完整')
        return false
      }
      this.listData = []
      this.listObj = {}
      for (let key in val) {
        if (arr.indexOf(key) == -1) {
          let obj = {}
          if (key == 'Status') {
            obj.name = '状态'
          } else if (key == 'DepartmentStr') {
            obj.name = '部门'
          } else {
            obj.name = key
          }
          obj.field = key
          obj.value = val[key]
          this.listData.push(obj)
          this.listObj[key] = val[key]
        }
      }

      this.liastStatus = true
    },
    listAction() {
      if (!this.listId || !this.listType) {
        this.$message.error('数据出错，请关掉重试')
        return false
      }
      let self = this
      this.$message.loading('操作中...', 0)
      this.$axios.get(
        129451,
        {
          data: this.listObj,
          Type: this.listType,
          Id: this.listId,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)

            if (self.listType == 'salary') {
              self.salary_list()
            } else {
              self.base_list()
            }
            self.listModal(false, '')
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    //列表修改end
    //工资单start
    changeSalaryMonth(val, str) {
      this.salaryPage = 1
      this.salaryMonth = str
      this.salary_list()
    },
    onChangeSchool(value) {
      this.salaryPage = 1
      this.DepartmentId = []
      this.DepartmentId.push(value)
      this.salary_list()
    },
    create_payslip() {
      // if(this.DepartmentId.length==0){
      //      this.$message.error('请先选择部门');
      //      return false;
      // }
      let url =
        'https://ims.qitianzhen.cn/index.php?m=Kms&c=Perform&a=create_payslip&month=' +
        this.salaryMonth +
        '&orgid=' +
        this.DepartmentId.join(',')
      window.open(url)
    },
    ChangeSalaryPage(val) {
      this.salaryPage = val
      this.salary_list()
    },
    changeStatus(id, status) {
      let self = this
      this.$message.loading('操作中...', 0)
      this.$axios.get(
        129452,
        {
          Id: id,
          Status: status,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.salary_list()
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    salaryEmpty() {
      this.DepartmentId = []
      this.salaryPage = 1
      this.salaryMonth = this.getMonth(0)
      this.salarySearch = ''
      this.salary_list()
    },
    _salarySearch() {
      this.salaryPage = 1
      this.salary_list()
    },
    salary_list() {
      let self = this
      this.salarycolumns = []
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        129444,
        {
          Page: self.salaryPage,
          PageSize: self.salaryPageSize,
          Month: self.salaryMonth,
          DepartmentId: self.DepartmentId.join(','),
          KeyWord: self.salarySearch,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.salaryList = res.data.data
            let salarycolumns = res.data.data[0]
            let arr = ['Id', '员工ID', '员工Id']
            for (let key in salarycolumns) {
              if (arr.indexOf(key) == -1) {
                let obj = {
                  title: '',
                  fixed: '',
                  dataIndex: '',
                  scopedSlots: { customRender: '' },
                }

                if (key == 'Status') {
                  obj.title = '状态'
                } else if (key == 'DepartmentStr') {
                  obj.title = '部门'
                  obj.ellipsis = true
                } else {
                  obj.title = key
                }

                obj.dataIndex = key
                obj.scopedSlots.customRender = key
                self.salarycolumns.push(obj)
                // if(key !='DepartmentStr'){
                //
                // }
              }
            }
            let idObj = {
              title: '操作',
              fixed: '',
              dataIndex: 'Id',
              scopedSlots: { customRender: 'Id' },
            }
            self.salarycolumns.push(idObj)
            for (let i = 0; i < self.salarycolumns.length; i++) {
              // self.salarycolumns[i].ellipsis = true;
              if (i < 3) {
                self.salarycolumns[i].fixed = 'left'
                self.salarycolumns[i].width = 80
              } else {
                if (self.salarycolumns.length == i) {
                  self.salarycolumns[i].width = ''
                } else {
                  // self.salarycolumns[i].width = 80;
                }
              }
              if (i + 1 == self.salarycolumns.length) {
                self.salarycolumns[i].fixed = 'right'
                self.salarycolumns[i].ellipsis = true
                self.salarycolumns[i].width = 80
              }
            }
            self.salaryTotal = res.data.count
          } else {
            self.salaryList = []
            self.salaryTotal = ''
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _salarydown() {
      // if(this.DepartmentId.length==0){
      //    this.$message.error('请先选择部门');
      //    return  false;
      // }
      let url =
        'https://sapi.sikegroup.com/v1/performance/salary_list?tab=download&Month=' +
        this.salaryMonth +
        '&DepartmentId=' +
        this.DepartmentId.join(',') +
        '&KeyWord=' +
        this.salarySearch
      window.open(url)
    },
    //工资单end
    //数据项目 start
    dataEmpty() {
      this.dataPage = 1
      this.dataSearch = ''
      this.Type = ''
      this.data_field_list()
    },
    _dataSearch() {
      this.dataPage = 1
      this.data_field_list()
    },
    data_field_list() {
      let self = this
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        129441,
        {
          Type: self.Type || '',
          Page: self.dataPage,
          PageSize: self.dataPageSize,
          KeyWord: self.dataSearch,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.dataList = res.data.data
            self.total = res.data.count
          } else {
            self.total = 0
            self.dataList = []
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    ChangePage(val) {
      this.dataPage = val
      this.data_field_list()
    },
    changeType(val) {
      this.dataPage = 1
      this.Type = val
      this.data_field_list()
    },
    dataDel(id) {
      let self = this
      this.$message.loading('操作中...', 0)
      this.$axios.post(
        129442,
        {
          Id: id,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.data_field_list()
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
      this.data_field_list()
    },
    dataSave(data) {
      this.Id = data.Id
      this.FieldName = data.FieldName
      if (data.Person == 1) {
        this.Person = true
      } else {
        this.Person = false
      }
      if (data.School == 1) {
        this.School = true
      } else {
        this.School = false
      }
      if (data.Performance == 1) {
        this.Performance = true
      } else {
        this.Performance = false
      }
      if (data.Condition == 1) {
        this.Condition = true
      } else {
        this.Condition = false
      }
      if (data.Count == 1) {
        this.Count = true
      } else {
        this.Count = false
      }
      if (data.Editable == 1) {
        this.Editable = true
        this.EditableStatus = false
      } else {
        this.Editable = false
        this.EditableStatus = true
      }
      if (data.hasOwnProperty('Remark')) {
        this.Remark = data.Remark
      }
      this.PositionItems = data.PositionItems
        ? data.PositionItems.split('#')
        : []

      this.dataStatus = true
    },
    dataModal(val) {
      this.dataStatus = val
      if (!val) {
        this.Id = ''
        this.FieldName = ''
        this.Person = false
        this.School = false
        this.Performance = false
        this.Condition = false
        this.Count = false
        this.Editable = false
        this.EditableStatus = false
        this.Remark = ''
      }
    },
    changeCheck(e, name) {
      if (name == 'Person') {
        this.Person = e.target.checked
      } else if (name == 'School') {
        this.School = e.target.checked
      } else if (name == 'Performance') {
        this.Performance = e.target.checked
      } else if (name == 'Condition') {
        this.Condition = e.target.checked
      } else if (name == 'Count') {
        this.Count = e.target.checked
      } else if (name == 'Editable') {
        this.Editable = e.target.checked
      }
    },
    dataAction() {
      let self = this
      if (!this.FieldName) {
        this.$message.error('请输入字段名称')
        return false
      }
      this.$message.loading('操作中....', 0)
      this.$axios.post(
        129443,
        {
          Id: self.Id,
          FieldName: self.FieldName,
          Person: self.Person ? 1 : 0,
          School: self.School ? 1 : 0,
          Performance: self.Performance ? 1 : 0,
          Condition: self.Condition ? 1 : 0,
          Count: self.Count ? 1 : 0,
          Editable: self.Editable ? 1 : 0,
          Remark: self.Remark,
          PositionItems: self.PositionItems,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            this.$message.success(res.data.msg)
            self.dataModal(false)
            self.data_field_list()
            this.PositionItems = []
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    selectPositionItems(value) {
      this.PositionItems = value
    },

    //数据项目end
    changeMonth(val, str) {
      this.Month = str
      if (this.positionName) {
        this.tableList = []
        this.OrgId = ''
        this.item_org_list()
      }
    },
    position_list() {
      let self = this
      this.$axios.get(129435, {}, (res) => {
        if (res.data.code == 1) {
          self.positionList = res.data.data
        } else {
          self.positionList = []
        }
      })
    },
    changeOrg() {
      this.position_detail_new()
    },
    _index_name(id) {
      let self = this
      let Level = ''
      for (var i = 0; i < self.orgList.length; i++) {
        if (self.orgList[i].OrgId == id) {
          Level = self.orgList[i].Level
          break
        }
      }
      return Level
    },
    //部门列表
    item_org_list() {
      let self = this
      this.$axios.get(
        8000206,
        {
          Month: self.Month,
          Position: this.positionName,
        },
        (res) => {
          if (res.data.code == 1) {
            self.orgList = res.data.data
          } else {
            self.orgList = []
          }
        }
      )
    },
    item_org_list_copy() {
      let self = this
      this.$axios.get(
        129457,
        {
          Month: self.copyMonth,
          Position: this.positionNameCopy,
        },
        (res) => {
          if (res.data.code == 1) {
            self.orgList = res.data.data
          } else {
            self.orgList = []
          }
        }
      )
    },

    position_detail(val) {
      for (let i = 0; i < this.positionList.length; i++) {
        if (this.positionList[i].Position == val) {
          this.positionId = this.positionList[i].Id
        }
      }
      this.tableList = []
      this.OrgId = ''
      this.new_level = undefined
      this.item_org_list()
      // console.log(this.positionIndex)
    },
    position_detail_copy() {
      this.item_org_list_copy()
    },
    position_detail_new() {
      if (this.new_level) {
        let self = this
        self.tableList = []

        this.$message.loading('加载中..', 0)
        this.$axios.get(
          129449,
          {
            Id: this.positionId,
            Month: self.Month,
            OrgId: self.new_level,
          },
          (res) => {
            this.$message.destroy()
            if (res.data.code == 1) {
              self.tableList = res.data.data
              let id = []
              for (let i = 0; i < self.tableList.length; i++) {
                if (
                  self.tableList[i].formula.length == 0 &&
                  self.tableList[i].postcondition.length == 0
                ) {
                  if (self.tableList[i].hasOwnProperty('Id')) {
                    id.push(self.tableList[i].Id)
                    self.tableList.splice(i, 1)
                  }
                }
              }
              if (id.length > 0) {
                self.item_del(id)
              }
            } else {
              this.$message.error(res.data.msg)
              self.positionInfo = ''
            }
          }
        )
      }
    },
    //项目名称删除
    item_del(ids) {
      this.$axios.get(
        129447,
        {
          id: ids.join(','),
        },
        (res) => {
          if (res.data.code == 1) {
            // this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    addPosition() {
      if (!this.positionName) {
        this.$message.error('请先选择职位')
        return false
      }
      this.isRead = this.new_level ? true : false
      this.visible = true
    },
    _saveCclose() {
      this.visible = false
    },
    _close(val) {
      this.orgList = []
      this.visible = false

      if (typeof val != 'undefined' && val) {
        this.OrgId = val
      }
      if (this.positionName) {
        this.item_org_list()
        this.position_detail_new()
      }
    },
    addStatusModal(val) {
      this.addStatus = val
      if (!val) {
        this.Level = undefined
        this.Position = undefined
        this.position_list()
      }
    },
    _add() {
      // this.addStatus = true;
      // this.positionIndex = -1;
      this.addStatusModal(true)
    },
    toAction() {
      if (!this.Position && typeof this.Position != 'undefined') {
        this.$message.error('请输入职位名称')
        return false
      }
      let self = this
      this.$message.loading('操作中...', 0)
      this.$axios.get(
        1294381,
        {
          Position: self.Position || '',
          Level: self.Level || '',
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.addStatusModal(false)
            self.$message.success(res.data.msg)
          } else {
            self.$message.error(res.data.msg)
          }
        }
      )
    },
    _copyItem() {
      this.copyItemModal(true)
      this.copyOneId = ''
      this._getLevel()
    },
    copyItemModal(val) {
      this.copyItem = val
    },
    changeCopy(val) {
      this.copy_level = val
    },
    doCopy() {
      let self = this
      if (!this.copyMonth || !this.positionNameCopy || !this.copy_level) {
        this.$message.error('请选择完整信息')
        return false
      }

      this.$message.loading('操作中...', 0)
      this.$axios.get(
        129467,
        {
          CopyOneId: self.copyOneId, //复制某一条绩效项
          OldMonth: self.Month,
          OldPosition: self.positionName,
          OldOrgId: self.new_level,
          NewMonth: self.copyMonth,
          NewPosition: self.positionNameCopy,
          // NewOrgId: self.OrgIdCopy,
          NewOrgId: self.copy_level,
        },
        (res) => {
          this.$message.destroy()
          if (res.data.code == 1) {
            self.copyItemModal(false)
            self.$message.success(res.data.msg)
            this.OrgIdCopy = ''
            this.positionNameCopy = ''
          } else {
            self.$message.error(res.data.msg)
          }
        }
      )
      this.copyOneId = ''
    },
    _copyOne(id) {
      this.copyOneId = id
      this.copyItemModal(true)
    },
    changeCopyMonth(val, str) {
      this.copyMonth = str
      this.positionNameCopy = undefined
    },
    copyChangeSchool(value) {
      this.OrgIdCopy = value
    },
    _getLevel() {
      let self = this
      self.$axios.get(8012, {}, (res) => {
        if (res.data.code == 1) {
          self.level_list = res.data.data
        } else {
          self.level_list = []
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.other {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .other-list {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
.performanc {
  background: #ffffff;
  width: 100%;
  min-height: 100%;
  padding: 20px;

  .content {
    display: flex;

    .content-left {
      .content-left-item {
        border: 1px solid rgb(229, 231, 234);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        margin-bottom: 16px;
        border-radius: 5px;
        cursor: pointer;
      }

      .content-left-active {
        border: 1px solid #2894ff;
      }

      .add-input {
        margin-bottom: 10px;
      }

      .add-submit {
        margin-bottom: 10px;
      }
    }

    .content-right {
      //flex: 1;
      margin-left: 30px;

      .content-list {
        //width: auto;
        border: 1px solid #e5e7ea;
        padding: 10px;
        margin-bottom: 10px;

        .content-list-title {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .content-right-head {
          display: flex;
          align-items: center;
          border: 1px solid #e5e7ea;
          //width: auto;
          background: #fafafa;

          .right-head-item {
            width: 120px;
            text-align: center;
            border-right: 1px solid #e5e7ea;
            height: 30px;
            line-height: 30px;
            font-weight: 600;
          }

          .right-head-item:last-child {
            border-right: 0px;
          }
        }

        .content-right-warp {
          display: flex;
          align-items: center;
          border: 1px solid #e5e7ea;
          border-top: 0px;

          .right-warp-title {
            width: 139px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            height: 36px;
            line-height: 36px;
          }

          .right-warp-list {
            .warp-list {
              display: flex;
              align-items: center;
              //border-bottom: 1px solid #e5e7ea;
              //border-right: 1px solid #e5e7ea;

              .warp-list-item {
                width: 120px;
                text-align: center;
                //border-left: 1px solid #e5e7ea;
                height: 36px;
                line-height: 36px;
                //border-right: 1px solid #e5e7ea;
              }
            }

            .warp-list:last-child {
              border-bottom: 0px;
            }
          }

          .right-warp-right {
            display: flex;
            height: 100%;
            //background: rgb(240, 248, 255);
            .right-warp-right-item {
              width: 120px;
              text-align: center;
              //height: 100%;
              height: 36px;
              line-height: 36px;
            }
          }
        }

        .content-right-warp:hover {
          background: #e6f7ff;
        }
      }

      .right-add {
        width: 400px;
        background: #e5e5e5;
        height: 50px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        color: rgb(36, 36, 36);
        margin: auto;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
}

.list-field-more {
  width: 160px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right-head-num {
  width: 240px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.data-field {
  table {
    td,
    th {
      padding: 8px !important;
    }
  }

  .data-search {
    margin-bottom: 20px;
  }
}

.data-action {
  margin-bottom: 60px;

  .data-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .data-label {
      font-size: 14px;
      width: 120px;
    }

    .data-item {
      flex: 1;
    }
  }
}

.del-icon {
  color: red;
  font-size: 18px;
  margin-left: 20px;
  cursor: pointer;
}

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}
.upload-bth {
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
